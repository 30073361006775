import React from 'react';
import { Row, Col } from 'react-bootstrap'; // Import Row and Col for grid system
import Header from './Header';
import HomeImageSlider from './HomeImageSlider';
import HomePageSearchBar from '../../components/SearchBar/HomeSearchBar';
import LoginSignUpVector from '../../components/Vector/LoginSignupVector';
import HomePageFilterBttn from '../../components/filterButtons/HomePageFilterBttn';
import VegNonvegFilter from '../../components/filterButtons/VegNonvegFilter';
import RestaurantCard from '../ReusableComponent/restaurantCard';
import ExperienceBanner from './ExperienceBanner';
import ReferEarnBanner from '../ReusableComponent/ReferAndEarn';
import RestaurantListMobile from '../ReusableComponent/RestaurantListMobile';
import Sidebar from './HomeSideBar'; 
import useWindowWidth from '../../common/hooks/useWindowWidth';
import RestaurantListWeb from '../ReusableComponent/RestaurantListWeb';

function HomePage() {
  const windowWidth = useWindowWidth();

  return (
    <div>
      <Row> 
        {windowWidth > 1024 && (
          <Col lg={3} md={3} className="sidebar"> 
            <Sidebar /> 
          </Col>
        )}

        <Col lg={windowWidth > 1024 ? 9 : 12}> 
          <Header /> 

          <div className="main-content">
            <HomeImageSlider />
            <HomePageSearchBar />
            <div className={`d-flex ${windowWidth > 1024 ? 'flex-row' : 'flex-column'}`}>
              <HomePageFilterBttn />
            </div>
            
            <VegNonvegFilter />

            <div className={`d-flex ${windowWidth > 1024 ? 'flex-row' : 'flex-column'}`}>
              <RestaurantCard />
              <RestaurantCard />
              <RestaurantCard />
            </div>

            <ExperienceBanner />

            {/* Conditionally hide/show these RestaurantCards */}
            {windowWidth <= 1024 && ( // Show only on mobile view
              <div className={`d-flex flex-column`}> {/* Mobile view will always be column */}
                <RestaurantCard />
                <RestaurantCard />
              </div>
            )}

           {windowWidth > 1024 ? ( // RestaurantListWeb for desktop
              <RestaurantListWeb />
            ) : ( // RestaurantListMobile for mobile
              <RestaurantListMobile />
            )}

            <ReferEarnBanner />
          </div>
        </Col>
      </Row> 
    </div>
  );
}

export default HomePage;
