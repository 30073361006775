import React, { useEffect, useState } from 'react';
import TitleSection from '../ReusableComponent/TitleSection';
import { useNavigate, useParams } from 'react-router-dom';
import { URL } from '../../common/api/urls';
import { useAxios } from '../../common/api/hooks/useAxios';
import { Image, Table } from 'react-bootstrap';
import moment from 'moment';
import UserRegistration from '../ReusableComponent/UserRegistration';

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import ReuseCountPriceEdit from './ReuseCountPriceEdit';
//import { DonateUdbhav } from '../ordering/cartPage/CartPage';
import TaxesDropDownComponent from './TaxDropComponent';
import { Box, Button, TextField } from '@mui/material';
import { assets } from '../../assets/assets';
import ExpsuccessModal from '../ordering/modals/ExpsuccessModal';
import { isEmpty } from '../../common/utility/helperFunc';

function ExperienceCheckout() {
  const navigate = useNavigate();
  const { restaurantId, experienceId } = useParams();
  const [successModal, showSuccessModal] = useState(false);
  const [cancel, experienceData, error, loaded, reset, executeAPI] = useAxios();
  // eslint-disable-next-line
  const [cancelExp, DataExp, errorExp, loadedExp, resetExp, executeExpAPI] =
    useAxios();
  // eslint-disable-next-line
  const [
    cancelExpPatch,
    DataExpPatch,
    errorExpPatch,
    loadedExpPatch,
    resetExpPatch,
    executeExpPatchAPI,
  ] = useAxios();
  const [
    // eslint-disable-next-line
    transactioncancel,
    // eslint-disable-next-line
    transactionresponseData,
    // eslint-disable-next-line
    transactionerror,
    // eslint-disable-next-line
    transactionloaded,
    // eslint-disable-next-line
    transactionreset,
    transactionexecuteAPI,
  ] = useAxios();

  const [displayTaxes, setDisplayTaxes] = useState(false);
  const [displaySurCharges, setDisplaySurCharges] = useState(false);
  const [activeTab, setactivetab] = useState(1);
  const [getAddExperienceData, setBookExpData] = useState(
    JSON.parse(sessionStorage.getItem('expBookData')) || []
  );

  // const getAddExperienceData = JSON.parse(
  //   sessionStorage.getItem('expBookData')
  // );

  useEffect(() => {
    if (experienceId !== undefined && experienceId !== null) {
      executeAPI(`${URL.EXPERIENCE}/${experienceId}`, 'GET');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceId]);
  // const getAddExperienceData = {
  //   experienceId: '65b7a8590442e1d118c5135d',
  //   vendorId: '616fee8f176fc97838c32114',
  //   restaurantId: '616fee92176fc97838c32116',
  //   userId: '63dcff6ff81b8e68c2a0bb10',
  //   expType: 'SPECIAL',
  //   requestId: '',
  //   userDetails: {
  //     mobileNumber: '8639850369',
  //     countryCode: '+91',
  //     name: 'Rajkumar Chirumalla',
  //     fcmToken:
  //       'fhs_DpliQ-O_s_jR4sy57f:APA91bEQHtw1mVqnqmJoBliLHDHMlDk5-CJtWi_TJXO-nQBsnaUqRTgLBhkbTcRT3vLSGz3-Yk9m3lZFqQYqAMeMjwUNFEzn9dLayeQtbbpiCUMkli_oC_2IheCvmO_01VPOG3gd39xt',
  //     email: 'chirumallarajkumar9@gmail.com',
  //   },
  //   reservationDate: '2024-02-15',
  //   reservationTime: '12:15 PM',
  //   adultCount: 2,
  //   adultPrice: 50,
  //   adultPriceChanged: false,
  //   kidsCount: 0,
  //   kidsPrice: 40,
  //   kidsPriceChanged: false,
  //   isPackagesActive: false,
  //   package: {
  //     name: '',
  //     count: 0,
  //     price: 0,
  //     adults: 0,
  //     kids: 0,
  //   },
  //   highchairAccess: false,
  //   highchairCount: 0,
  //   handicapCount: 0,
  //   seatingPreference: '',
  //   occasion: '',
  //   donation: 0,
  //   tableNo: '',
  //   paymentData: {
  //     paymentMethod: 0,
  //     totalAmount: 105,
  //     amount: 100,
  //     donationAmount: 0,
  //   },
  //   taxes: {
  //     CGST: 2.5,
  //     SGST: 2.5,
  //   },
  //   taxesTotal: 5,
  //   surCharges: {},
  //   surChargesTotal: 0,
  // };

  useEffect(() => {
    if (isEmpty(getAddExperienceData)) {
      navigate(`/restaurant/${restaurantId}/`);
    }
  }, [getAddExperienceData]);

  const getTotalAmount = (count, price) => {
    let totalPrice = price * count;
    return totalPrice;
  };
  const getExperienceTaxes = (amount, exp) => {
    let taxData = exp?.taxes || [];
    let formData = {
      taxes: {},
      taxesTotal: 0,
      surCharges: {},
      surChargesTotal: 0,
    };
    for (let i = 0; i < taxData.length; i++) {
      if (taxData[i].taxType === 'TAX') {
        if (taxData[i].type === 'PERCENTAGE') {
          formData['taxes'][taxData[i].code] =
            (amount * parseFloat(taxData[i].value)) / 100;
          formData['taxesTotal'] +=
            (amount * parseFloat(taxData[i].value)) / 100;
        } else {
          formData['taxes'][taxData[i].code] = parseFloat(taxData[i].value);
          formData['taxesTotal'] += parseFloat(taxData[i].value);
        }
      } else {
        if (taxData[i].type === 'PERCENTAGE') {
          formData['surCharges'][taxData[i].code] =
            (amount * parseFloat(taxData[i].value)) / 100;
          formData['surChargesTotal'] +=
            (amount * parseFloat(taxData[i].value)) / 100;
        } else {
          formData['surCharges'][taxData[i].code] = parseFloat(
            taxData[i].value
          );
          formData['surChargesTotal'] += parseFloat(taxData[i].value);
        }
      }
    }
    return formData;
  };

  const PayLaterTrans = () => {
    transactionexecuteAPI(
      `${URL.UPDATE_TRANSACTION}`,
      'POST',
      {
        paid: false,
        method: 'PAYLATER',
        amount: DataExp?.paymentData?.totalAmount,
        type: 'PAYLATER',
        date: moment().valueOf(),
        paymentType: 6,
        id: DataExp?._id,
        experience: true,
      },
      {
        Authorization: sessionStorage.getItem('accessToken'),
      }
    );
  };

  const postExp = () => {
    const orderPreparedDataNew = {
      ...getAddExperienceData,
      status: 'INITIAL',
    };

    orderPreparedDataNew.paymentData.paymentMethod = 6;
    orderPreparedDataNew.paymentData.paymentPending = false;
    orderPreparedDataNew.paymentMethod = 6;
    orderPreparedDataNew.paymentPending = false;
    executeExpAPI(`${URL.USEREXP}`, 'POST', orderPreparedDataNew, {
      Authorization: sessionStorage.getItem('accessToken'),
    });
  };

  const UpdateExp = () => {
    const updateData = {
      paymentStatus: 0,
      status: 'PENDING',
      pointOfEntry: 'WEBAPP',
      waitTime: 5,
    };

    executeExpPatchAPI(`${URL.USEREXP}/${DataExp?._id}`, 'PATCH', updateData, {
      Authorization: sessionStorage.getItem('accessToken'),
    });
  };

  useEffect(() => {
    if (DataExp) {
      PayLaterTrans();
    }
  }, [DataExp]);

  useEffect(() => {
    if (transactionresponseData) {
      UpdateExp();
    }
  }, [transactionresponseData]);

  useEffect(() => {
    if (DataExpPatch) {
      showSuccessModal(true);
    }
  });

  const handleSave = () => {
    if (activeTab === 1) {
      setactivetab(2);
    } else if (activeTab === 2) {
      postExp();
    }
  };
  const handleCancel = () => {
    if (activeTab === 1) {
      navigate(
        `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`
      );
    } else if (activeTab === 2) {
      setactivetab(1);
    }
  };

  return (
    <div>
      <TitleSection
        bg={'#FFFFFF'}
        title={experienceData?.restaurantName || 'Experience'}
        // backRoute={
        //   `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`
        // }
        backButton={() =>
          navigate(
            `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`,
            { state: { actionBy: 'PAYMENT_SUMMARY' } }
          )
        }
        className={'box-shadow-down'}
        resticon={experienceData?.restaurantId?.logo_url_thumbnails}
      />
      <div className="p-2">
        <div className="bg-indigo payment_screen my-2">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="f12-400">Total Amount To be paid</div>
            <div className="f12-700 primary-color">{`Experience ID: ${
              experienceData && experienceData?.expId
            }`}</div>
          </div>
          <div className="d-flex justify-content-between  align-items-centeras my-2">
            <div className="f24-700">
              {`₹ ${getAddExperienceData?.paymentData?.totalAmount?.toFixed(
                2
              )} ` || 0}
            </div>
            <div className="eperience_name f10-700">
              {experienceData?.subCategory?.title}
            </div>
          </div>
        </div>

        <div className="topDateTimeBox d-flex">
          <div className="f14-400">{`Date: ${moment(
            getAddExperienceData?.reservationDate
          ).format('Do MMM YYYY')}`}</div>
          <div className="f14-400">{`Time: ${getAddExperienceData?.reservationTime}`}</div>
        </div>

        <div className="f12-400 primary-color my-2">{`(Seat Selection is not available at this moment, will be assigned at the restaurant.)`}</div>
      </div>

      <div className="divider_line " style={{ paddingBottom: '30px' }}>
        {!getAddExperienceData?.isPackagesActive ? (
          <div>
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.adultCount} Adults`}
              onClickEdit={() =>
                navigate(
                  `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`,
                  { state: { actionBy: 'PAYMENT_SUMMARY' } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.adultCount,
                getAddExperienceData?.adultPrice
              )}
            />
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.kidsCount} Kids`}
              onClickEdit={() =>
                navigate(
                  `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`,
                  { state: { actionBy: 'PAYMENT_SUMMARY' } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.kidsCount,
                getAddExperienceData?.kidsPrice
              )}
            />
          </div>
        ) : (
          <div>
            <div className="f16-400" style={{ color: '#A28AE4' }}>
              {getAddExperienceData?.package?.name}
            </div>
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.package?.count} Package`}
              onClickEdit={() =>
                navigate(
                  `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`,
                  { state: { actionBy: 'PAYMENT_SUMMARY' } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.package?.count,
                getAddExperienceData?.package?.price
              )}
            />
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.adultCount} Adults`}
              onClickEdit={() =>
                navigate(
                  `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`,
                  { state: { actionBy: 'PAYMENT_SUMMARY' } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.adultCount,
                getAddExperienceData?.adultPrice
              )}
            />
            <ReuseCountPriceEdit
              displayEdit={activeTab === 1 ? true : false}
              count={`${getAddExperienceData?.kidsCount} Kids`}
              onClickEdit={() =>
                navigate(
                  `/restaurant/${restaurantId}/experience/${experienceId}/bookexp`,
                  { state: { actionBy: 'PAYMENT_SUMMARY' } }
                )
              }
              totalPrice={getTotalAmount(
                getAddExperienceData?.kidsCount,
                getAddExperienceData?.kidsPrice
              )}
            />
          </div>
        )}
        {activeTab === 1 && (
          <DonateUdbhav
            //  OrderData={getAddExperienceData}
            setOrderData={setBookExpData}
            //  exp={true}
          />
        )}
        {activeTab === 1 && (
          <>
            <div className="f16-700 mb-4">{`Payment Summary`}</div>
            <div className="paymentSummary mb-4 d-flex">
              <div className="f16-400 my-2">{`Sub Total`}</div>
              <div className="f16-400 my-2">{`₹ ${getAddExperienceData?.paymentData?.amount?.toFixed(
                2
              )}`}</div>
            </div>
          </>
        )}

        <div
          style={{
            borderBottomWidth: 1,
            borderStyle: 'dashed',
            borderColor: '#00000029',
          }}
        ></div>

        <TaxesDropDownComponent
          openState={displayTaxes}
          onPressAction={() => setDisplayTaxes(!displayTaxes)}
          taxTitle={'Taxes'}
          taxPrice={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.taxesTotal
          }
          arrData={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.taxes
          }
        />
        <TaxesDropDownComponent
          openState={displaySurCharges}
          onPressAction={() => setDisplaySurCharges(!displaySurCharges)}
          taxTitle={'Surcharges'}
          taxPrice={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.surChargesTotal
          }
          arrData={
            getExperienceTaxes(
              getAddExperienceData?.paymentData?.amount,
              experienceData
            )?.surCharges
          }
        />
        {activeTab === 2 && <hr />}
        <div className="paymentSummary d-flex mb-4">
          <div className="f16-400 ">{`Donation`}</div>
          <div className="f16-400 ">{`₹ ${getAddExperienceData?.paymentData?.donationAmount?.toFixed(
            2
          )}`}</div>
        </div>
        {activeTab === 1 && (
          <>
            <div
              style={{
                borderBottomWidth: 1,
                borderStyle: 'dashed',
                borderColor: '#00000029',
              }}
            ></div>
            <div
              className="paymentSummary d-flex "
              style={{ marginBottom: '20px' }}
            >
              <div className="f16-700 ">{'Grand Total'}</div>
              <div className="f16-700 ">{`₹ ${getAddExperienceData?.paymentData?.totalAmount?.toFixed(
                2
              )}`}</div>
            </div>
          </>
        )}
        {activeTab === 2 && (
          <div className="p-2">
            <div className="f16-700">Pay With</div>
            <div className="my-3 p-2 shadow border-active border-radius flex-between">
              <div className="d-flex">
                <div>
                  <Image
                    src={require('../../assets/images/IconsandImages/paylater.jpeg')}
                    width={24}
                  />
                </div>
                <div className="f14-700 flexCenter px-3">Pay At Site</div>
              </div>
              <div>
                <KeyboardArrowRightRoundedIcon />
              </div>
            </div>
          </div>
        )}
      </div>
      <hr />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          position: 'fixed',
          bottom: 0,
          width: '-webkit-fill-available',
          backgroundColor: assets.colors.white,
        }}
        p={2}
        mt={3}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={() => handleCancel()}
          style={{
            borderRadius: '15px',
            color: 'black',
            border: '1px solid #C4C4C4',
            textTransform: 'capitalize',
          }}
          className="bg-white mr-2"
          fullWidth
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{
            borderRadius: '15px',
            border: '1px solid #C4C4C4',
            color: 'white',
            backgroundColor: assets.colors.primary,
            textTransform: 'capitalize',
          }}
          // disabled={!(tableNumber || paxNum || paxNumber)}
          fullWidth
          onClick={handleSave}
        >
          Submit
        </Button>
      </Box>
      <ExpsuccessModal
        exp_id={DataExp?._id}
        successModal={successModal}
        showSuccessModal={(e) => showSuccessModal(e)}
        restaurantId={restaurantId}
      />
    </div>
  );
}
export default ExperienceCheckout;

export function DonateUdbhav({ setOrderData }) {
  const [expandTip, setExpandTip] = useState(false);
  const [custom, setcustom] = useState(false);
  const expData = JSON.parse(sessionStorage.getItem('expBookData'));
  const [tiPAmount, setTipAmount] = useState(
    expData?.paymentData?.donationAmount || 0
  );
  const [customAmount, setcustomAmount] = useState(null);
  const [showTips, setShowTips] = useState(false);

  const handleAddTip = (e) => {
    setTipAmount(e.target.value);
    setShowTips(false);
  };
  const onCustomClick = () => {
    setTipAmount(0);
    setcustom(true);
    setcustomAmount(0);
  };
  const customHandler = (e) => {
    setcustomAmount(e.target.value);
  };

  const handleChange = () => {
    setShowTips(true);
  };

  const handleCancel = () => {
    setcustom(false);
    setTipAmount(0);
    setcustom(0);
  };

  const handleSubmit = () => {
    setTipAmount(customAmount);
    setShowTips(false);
    setcustom(false);
  };

  useEffect(() => {
    let numericTiPAmount = parseFloat(tiPAmount) || 0;
    const expData = JSON.parse(sessionStorage.getItem('expBookData'));
    let newOrderData = {
      ...expData,
      paymentData: {
        donationAmount: numericTiPAmount,
        totalAmount: expData?.paymentData?.totalAmount + numericTiPAmount,
        amount: expData.paymentData.amount,
        paymentMethod: 6,
      },
    };

    sessionStorage.setItem('expBookData', JSON.stringify(newOrderData));
    setOrderData(newOrderData);
  }, [tiPAmount]);
  return (
    <>
      <div className="add-tip">
        <div>
          Donate to Udbhav{' '}
          <span className="f12-500" style={{ color: '#2196f3' }}>
            Learn More
          </span>
        </div>
        <div className="primary-color f12-700" onClick={handleChange}>
          Change
        </div>
        <div>₹ {tiPAmount}</div>
      </div>
      <div className="add-tip-dropdown">
        {showTips ? (
          <>
            {!custom ? (
              <div>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={0}
                  className="m-3"
                >
                  None
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={5}
                  className="m-3"
                >
                  ₹ 5
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={10}
                  className="m-3"
                >
                  ₹ 10
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={handleAddTip}
                  value={20}
                  className="m-3"
                >
                  ₹ 20
                </Button>
                <Button
                  variant="outline-secondary"
                  className="m-3"
                  onClick={onCustomClick}
                >
                  custom
                </Button>
              </div>
            ) : null}
            {custom ? (
              <div className="p-3">
                <div>
                  <TextField
                    id="outlined-custom"
                    variant="outlined"
                    value={customAmount}
                    onChange={customHandler}
                    placeholder="Enter amount"
                    label="Enter amount"
                    type="number"
                  />
                </div>
                <div className="d-flex justify-content-space align-items-center mt-3">
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    style={{
                      borderRadius: '15px',
                      color: 'black',
                      border: '1px solid #C4C4C4',
                      textTransform: 'capitalize',
                    }}
                    className="bg-white mr-2"
                    fullWidth
                  >
                    cancel
                  </Button>

                  <Button
                    color="white"
                    variant="contained"
                    style={{
                      borderRadius: '15px',
                      backgroundColor: 'rgb(252, 90, 71) !important',
                      textTransform: 'capitalize',
                      color: 'whitesmoke',
                    }}
                    className="bg-danger ml-2"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
}
