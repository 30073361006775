import React from "react";
//import Carousel from 'react-bootstrap/Carousel'
import { Image } from "react-bootstrap";

const RestaurantGallery = (props) => {
  return (
    <>
      <div className="m-2">
        <div className="d-flex f16-700 justify-content-between py-2">
          {" "}
          Images{" "}
          {/* <span className="f10-400" style={{ color: "#2196f3" }}>
            View All
          </span> */}
        </div>
        <div className="d-flex ">
            {props?.data?.restaurant_pictures?.length ? (
                 <div className="col-4 p-2">
                 <Image
                   src={props?.data?.restaurant_pictures[0]}
                   alt="Image"
                   width="100%"
                   height="150px"
                   style={{borderRadius:"12px"}}
                 />
               </div>
            ) : null}
            {props?.data?.restaurant_pictures?.length > 1 ? (
                 <div className="col-4 p-2">
                 <Image
                   src={props?.data?.restaurant_pictures[1]}
                   alt="Image"
                   width="100%"
                   height="150px"
                   style={{borderRadius:"12px"}}
                 />
               </div>
            ) : null}
            {props?.data?.restaurant_pictures?.length > 2 ? (
                 <div className="col-4 p-2">
                 <Image
                   src={props?.data?.restaurant_pictures[2]}
                   alt="Image"
                   width="100%"
                   height="150px"
                   style={{borderRadius:"12px"}}
                 />
               </div>
            ) : null}  
        </div>
      </div>
    </>
  );
};
export default RestaurantGallery;
