import React, { useState } from 'react';
//import './PersonlizationCustomization.css'; // CSS file for styling

const TabComponent = ({ tabs, initialTab, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    if (onTabChange) {
      onTabChange(tabName);
    }
  };

  return (
    <div className="tab-wrapper">
      <div className="tabs-container">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabChange(tab)}
          >
            <div
              className={`${activeTab === tab ? 'f14-700' : 'f14-700'}`}
              style={{ color: activeTab !== tab && '#9E9E9E' }}
            >
              {tab}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabComponent;
