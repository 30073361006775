import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)(({ customStyles }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& fieldset': {
      borderColor: '#rgb(0 0 0 / 12%)', // Grey border
    },
    '&:hover fieldset': {
      borderColor: 'darkgrey', // Darker border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'grey', // Grey border when focused
    },
  },
  ...customStyles,
}));

const ReusableTextField = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  required,
  error,
  helperText,
  disabled,
  fullWidth,
  variant,
  inputProps,
  customStyles,
  className,
  sx,
  onBlur,
  onFocus,
  size,
  name,
}) => {
  return (
    <StyledTextField
      label={label}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      error={error}
      helperText={helperText}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      inputProps={inputProps}
      className={className}
      sx={sx}
      onBlur={onBlur}
      onFocus={onFocus}
      customStyles={customStyles}
      size={size}
      margin="normal"
    />
  );
};

// Default Props
ReusableTextField.defaultProps = {
  type: 'text',
  required: false,
  error: false,
  helperText: '',
  disabled: false,
  fullWidth: true,
  variant: 'outlined',
  inputProps: {},
  customStyles: {},
  className: 'my-2',
  sx: {},
  size: 'medium',
  onBlur: () => {},
  onFocus: () => {},
};

// Prop Types for validation
ReusableTextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'tel',
    'password',
    'number',
    'url',
    'search',
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  inputProps: PropTypes.object,
  customStyles: PropTypes.object,
  className: PropTypes.string,
  sx: PropTypes.object,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default ReusableTextField;
