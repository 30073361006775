import React from 'react';
import { Nav } from 'react-bootstrap';
import ameliologowithtext from '../../assets/images/IconsandImages/amealiologowithtext.png'; // Add the Amelio logo path
import DiscoverIcon from '../../assets/images/IconsandImages/DiscoverIcon.png';
import ExperienceIcon from '../../assets/images/IconsandImages/ExperienceIcon.png';
import TrackIcon from '../../assets/images/IconsandImages/TrackIcon.png';
import ExploreIcon from '../../assets/images/IconsandImages/ExploreIcon.png';
import SocialIcon from '../../assets/images/IconsandImages/SocialIcon.png';

const Sidebar = () => {
  return (
    <Nav className="flex-column sidebar" style={{ width: '250px', height: '100vh', backgroundColor: '#fff', padding: '20px', borderRight: '1px solid lightgray' }}>
      
      {/* Amelio Logo */}
      <div style={{ textAlign: 'center', marginBottom: '30px' }}>
        <img src={ameliologowithtext} alt="Amelio Logo" style={{ width: '150px', marginBottom: '10px' }} />
      </div>
      
      {/* Sidebar Links */}
      <Nav.Link href="#discover" className="sidebar-link" style={{ marginBottom: '20px' }}>
        <img src={DiscoverIcon} alt="Discover" style={{ width: '24px', marginRight: '10px' }} />
        <span>Discover</span>
      </Nav.Link>
      <Nav.Link href="#experience" className="sidebar-link" style={{ marginBottom: '20px' }}>
        <img src={ExperienceIcon} alt="Experience" style={{ width: '24px', marginRight: '10px' }} />
        <span>Experience</span>
      </Nav.Link>
      <Nav.Link href="#track" className="sidebar-link" style={{ marginBottom: '20px' }}>
        <img src={TrackIcon} alt="Track" style={{ width: '24px', marginRight: '10px' }} />
        <span>Track</span>
      </Nav.Link>
      <Nav.Link href="#explore" className="sidebar-link" style={{ marginBottom: '20px' }}>
        <img src={ExploreIcon} alt="Explore" style={{ width: '24px', marginRight: '10px' }} />
        <span>Explore</span>
      </Nav.Link>
      <Nav.Link href="#social" className="sidebar-link" style={{ marginBottom: '20px' }}>
        <img src={SocialIcon} alt="Social" style={{ width: '24px', marginRight: '10px' }} />
        <span>Social</span>
      </Nav.Link>
    </Nav>
  );
};

export default Sidebar;
