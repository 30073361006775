import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// Axios instance with base configuration
const apiService = axios.create({
  baseURL: BASE_URL || '',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

// Request interceptor to add token or modify the request if needed
apiService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for centralized success/error handling
apiService.interceptors.response.use(
  (response) => response.data, // Simplifies response, only returning data
  (error) => {
    const errorMessage =
      error.response?.data?.message || 'An unknown error occurred';
    return Promise.reject(new Error(errorMessage));
  }
);

// Export the functional API methods
export const get = (url, config = {}) => apiService.get(url, config);
export const post = (url, data, config = {}) =>
  apiService.post(url, data, config);
export const patch = (url, data, config = {}) =>
  apiService.patch(url, data, config);
