import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
//import WaitListDetails from './WaitListDetails';
import QRCode from 'react-qr-code';
import CloseIcon from '@mui/icons-material/Close';
import OrderDetails from './OrderDetails';
import { Divider } from '@mui/material';
import {
  NEW_PAYMENT_TYPE,
  PAYMENT_STATUS,
} from '../../../common/enums/OrderEnums';
import moment from 'moment';
import { assets } from '../../../assets/assets';
import { screenSetup } from '../../experience-details/ScreenSetup';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ExpTrackScreenQr = ({ open, handleCloseQr, data }) => {
  const [cancelModal, setCancelModal] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={handleCloseQr}
      //  TransitionComponent={Transition}
      className="hidescrollbar"
    >
      <div className="hidescrollbar">
        <div
          className="pl-4  py-2 package-pass-card"
          style={{
            backgroundImage: `url('${data?.experienceId?.photos[0]}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '0px 0px 16px 16px',
          }}
        >
          <div className="d-flex justify-content-end pr-4 my-3">
            {' '}
            <CloseIcon
              onClick={handleCloseQr}
              sx={{ backgroundColor: 'white', borderRadius: '50%' }}
            />
          </div>
        </div>

        <div className="text-center f16-700 my-3">
          {data?.experienceId?.name}
        </div>
        <hr className="my-2" sx={{ border: 1 }} />
        <div className="row px-3">
          <div className="col-2 text-grey f12-700">venue:</div>
          <div className="col-8  f12-700">
            {data?.experienceId?.venueAddress}
          </div>
        </div>
        <hr className="my-2" sx={{ border: 1 }} />
        <div className="row p-3">
          <div className="col f14-700 nowrap">Experience Ticket Details</div>
          <div className="col f12-700" style={{ color: assets.colors.primary }}>
            Exp ID:{data?.experienceId?.expId}
          </div>
        </div>
        <div className="row p-3">
          <div className="col">
            <div>
              <div className="f14-700">
                {moment(data?.createdAt).format('DD MMM YYYY')}
              </div>
              <div className="f12-500 text-grey">Experience Date</div>
            </div>
          </div>
          <div className="col">
            <div>
              <div className="f14-700">
                {moment(data?.createdAt).format('hh:mm A')}
              </div>
              <div className="f12-500 text-grey">Experience Time</div>
            </div>
          </div>
        </div>
        <hr className="my-2" sx={{ border: 1 }} />
        <div className="f12-700 text-center"> show Qr Code to Attendant</div>
        <div className="my-3 flexCenter">
          <QRCode
            value={JSON.stringify({
              exp_id: data?.experienceId?.expId,
            })}
            size={140}
          />
        </div>
        <div
          className="f16-700 mt-4 mb-4 text-center"
          style={{ color: 'black' }}
        >
          {data?.experienceId?.restaurantName}{' '}
        </div>
        <div className="f14-500 text-center">
          show this pass at the Entry or to the Server
        </div>
        <hr />
        <div className="p-4">
          <div className="row">
            <div className="col">
              <div>
                <div className="f14-700">{data?.userDetails?.name}</div>
                <div className="f12-500 text-grey">Name</div>
              </div>
            </div>
            <div className="col">
              {/* <div>
                <div className="f14-700">{data?.status}</div>
                <div className="f12-500 text-grey">status</div>
              </div> */}
            </div>
          </div>
          <hr className="my-2" sx={{ border: 1 }} />
          <div className="row">
            <div className="col">
              <div>
                <div className="f14-700">#{data?.requestId}</div>
                <div className="f12-500 text-grey">Request ID</div>
              </div>
            </div>
            <div className="col">
              <div>
                <div className="f14-700">{data?.status}</div>
                <div className="f12-500 text-grey">status</div>
              </div>
            </div>
          </div>
          <hr className="my-2" sx={{ border: 1 }} />
          <div className="row">
            <div className="col">
              <div>
                <div className="f14-700">₹{data?.paymentData?.totalAmount}</div>
                <div className="f12-500 text-grey">Total Amount</div>
              </div>
            </div>
            <div className="col">
              <div>
                <div className="f14-700">
                  {' '}
                  {PAYMENT_STATUS[data?.paymentStatus]}
                </div>
                <div className="f12-500 text-grey">payment Status</div>
              </div>
            </div>
          </div>
          <hr className="my-2" sx={{ border: 1 }} />
          <div className="row">
            <div className="col">
              <div>
                <div className="f14-700">
                  Party size :{' '}
                  {(data?.adultCount || 0) +
                    (data?.kidsCount || 0) +
                    data?.package?.count *
                      (data?.package?.adults + data?.package?.kids)}
                </div>
                <div className="f14-700 text-grey">
                  <div className="d-flex">
                    <div>
                      <div className="f14-700">
                        {data?.adultCount || data?.package?.adults}
                      </div>
                      <div className="f12-500 text-grey">Adults</div>
                    </div>
                    <div>
                      <div className="f14-700">
                        {' '}
                        {data?.kidsCount || data?.package?.kids}
                      </div>
                      <div className="f12-500 text-grey">Kids</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div>
                <div className="f14-700">
                  {NEW_PAYMENT_TYPE[data?.paymentData?.paymentMethod]}
                </div>
                <div className="f12-500 opacity-50">Payment method</div>
              </div>
            </div>
          </div>
          <hr className="my-2" sx={{ border: 1 }} />
          <div className="row">
            <div className="d-flex">
              <div className="f14-700 text-grey">Seating Preference</div>
              <div className="f14-700 mx-2  flexCenter">
                {data?.seatingPreference?.[0] || 'N/A'}
              </div>
            </div>
          </div>
          <hr className="my-2" sx={{ border: 1 }} />
          <div className="row">
            <div className="d-flex">
              <div className="f14-700 text-grey">Special Ocassion</div>
              <div className="f14-700 mx-2  flexCenter">{'N/A'}</div>
            </div>
          </div>

          <hr className="my-2" sx={{ border: 1 }} />
          <div className="row">
            <div className="col">
              <div>
                <div className="f14-700">
                  {moment(data?.createdAt).format('DD MMM YYYY')}
                </div>
                <div className="f12-500 text-grey">Created Date</div>
              </div>
            </div>
            <div className="col">
              <div>
                <div className="f14-700">
                  {moment(data?.createdAt).format('hh:mm A')}
                </div>
                <div className="f12-500 text-grey">Created Time</div>
              </div>
            </div>
          </div>
          <hr className="my-2" sx={{ border: 1 }} />
          <div className="row">
            <div className="col">
              <div>
                <div className="f14-700">
                  {moment(data?.reservationTime).format('DD MMM YYYY')}
                </div>
                <div className="f12-500 text-grey">Requested Date</div>
              </div>
            </div>
            <div className="col">
              <div>
                <div className="f14-700">
                  {moment(data?.reservationTime).format('hh:mm A')}
                </div>
                <div className="f12-500 text-grey">Request Time</div>
              </div>
            </div>
          </div>
          <hr className="my-2" sx={{ border: 1 }} />
        </div>
      </div>
    </Dialog>
  );
};

export default ExpTrackScreenQr;
