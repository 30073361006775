import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AnalyticPage } from "../../common/enums/GlobalEnums";
import { useAxios } from "../../common/api/hooks/useAxios";
import { URL } from "../../common/api/urls";

const Experiencecards = ({ data, id }) => {

  const navigate = useNavigate();

    // eslint-disable-next-line
  const [pageStatscancel, pageStatsresponseData, pageStatserror, pageStatsloaded, pageStatsreset, pageStatsexecuteAPI] = useAxios();


  const handleClickcount = () => {
    if(sessionStorage.getItem("BroserId")){
      const params = {
        "deviceType":"",
        "page":AnalyticPage.EXP_VIEW,
        "deviceId":sessionStorage.getItem("BroserId"),
        "app":"WEB_APP"
      }
        pageStatsexecuteAPI(`${URL.PAGESTATS}?restaurantId=${id}`,"POST",params)
    } 
  }
  return (
    <>
      <div className="p-3">
        <div className="d-flex f16-700 justify-content-between py-2">
          {" "}
          Experiences{" "}
          <Link to={`/restaurant/${id}/experience`}>
            <span className="f10-400" style={{ color: "#2196f3" }}>
              View All
            </span>
          </Link>
        </div>
        <Carousel variant="dark" controls={false} indicators={false} slide>
          {data?.map((data, index) => {
            return (
              <Carousel.Item key={data + index}>
                <div
                  style={{
                    background: `url('${data?.photos?.[0]}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "160px",
                    borderRadius: "16px",
                    position: "relative",
                  }}
                  className="overlay"
                >
                  <div className="expcard-headingsection w-100">
                    <div className="f16-500 px-1" style={{ color: "white" }}>
                      {" "}
                      {data?.name}
                    </div>
                    <div className="px-2">
                      <button
                        className="primary-bg-color px-2 py-1 my-1 text-center w-100 text-white f14-500"
                        style={{ borderRadius: "12px", border: "none" }}
                        onClick={() =>{
                          navigate(
                            `/restaurant/${data?.restaurantId?._id}/experience/${data?._id}`
                          );
                          handleClickcount();
                        }
                        }
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};
export default Experiencecards;
