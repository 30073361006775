import { BackIconComponent } from "../ReusableComponent/BackIconComponent";
import { Image } from "react-bootstrap";

export const TopTitle = ({
  mainIcon,
  title,
  subTitle,
  subTitleWithIcon,
  backRoute,
  backButton,
}) => {
  return (
    <div className="top-title d-flex py-4 align-items-center">
      <div>
        <BackIconComponent route={backRoute} backButton={backButton} />
      </div>
      <div className="d-flex align-items-center">
        {mainIcon ? (
          <div className="mr-1">
            <Image
              width={50}
              src={mainIcon}
              alt="Restaurant Icon"
              title="Restaurant Icon"
              className="rounded-circle "
            />
          </div>
        ) : null}
        <div>
          <div className="f18-500">{title}</div>
          <div className="d-flex align-items-center">
            {subTitleWithIcon ? (
              <div className="mr-1">
                <Image
                  style={{ width: "10px" }}
                  src={subTitleWithIcon}
                  alt={subTitle}
                  title={subTitle}
                />
              </div>
            ) : null}{" "}
            {subTitle ? <div className="f14-400">{subTitle}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
};
