import * as React from "react";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch" } };

export default function CustomSwitch({ onChange, value }) {
  return (
    <div>
      <Switch
        {...label}
        size="small"
        color="success"
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
