import React, { useEffect, useState, Fragment } from 'react';
import { Col, Row, Container, Image, Modal } from 'react-bootstrap';
import { ITEMTYPEVEG } from '../../../common/enums/OrderEnums';
import { NOTAVAILABLEIMAGE } from '../../../common/enums/GlobalEnums';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
} from '@mui/material';
import { isEmpty } from '../../../common/utility/helperFunc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HeaderPersonalizationCustomization from './PersonlizarionCustomization/HeaderPersonlizationCustomization';
import TabComponent from './PersonlizarionCustomization/TabComponent';
import assets from '../../../assets/assets';
import CheckBoxWithText from './PersonlizarionCustomization/CheckBoxWithText';
import vegIcon from '../../../assets/images/IconsandImages/vegindicator.png'; // Example path, replace with your actual image imports
import nonVegIcon from '../../../assets/images/IconsandImages/nonveg_indicator.png';
import spicelevelicon from '../../../assets/images/IconsandImages/spicelevel.png';
import saltlevelicon from '../../../assets/images/IconsandImages/saltlevel.png';
import sugarlevelicon from '../../../assets/images/IconsandImages/sugarlevel.png';
import oillevelicon from '../../../assets/images/IconsandImages/oillevel.png';
import meatlevelicon from '../../../assets/images/IconsandImages/meatlevel.png';
import egglevelicon from '../../../assets/images/IconsandImages/egglevel.png';

const levelsdata = {
  spiceLevels: {
    name: 'Spice Level',
    levels: ['No Spice', 'Mild', 'Medium', 'Hot', 'Very Hot'],
    minimumColor: '#EE3A23',
    maximumColor: '#EE3A23',
    icon: spicelevelicon,
    key: 'spice_level',
  },
  saltLevels: {
    name: 'Salt Level',
    levels: ['No Salt', 'Low', 'Normal', 'Medium', 'Salty', 'Very Salty'],
    minimumColor: '#9E9E9E',
    maximumColor: '#9E9E9E',
    icon: saltlevelicon,
    key: 'salt_level',
  },
  sugarLevels: {
    name: 'Sugar Level',
    levels: ['No Sugar', 'Low', 'Normal', 'Medium', 'Sweet', 'Very Sweet'],
    minimumColor: '#6196FF',
    maximumColor: '#6196FF',
    icon: sugarlevelicon,
    key: 'sugar_level',
  },
  oilLevels: {
    name: 'Oil Level',
    levels: ['No Oil', 'Low', 'Normal', 'Medium', 'Regular', 'Extra'],
    minimumColor: '#FFB240',
    maximumColor: '#FFB240',
    icon: oillevelicon,
    key: 'fat_level' || 'oil_level',
  },
  meatLevels: {
    name: 'Meat Level',
    levels: ['Raw', 'Rare', 'Medium Rare', 'Medium', 'Regular', 'Well Done'],
    minimumColor: '#F9906F',
    maximumColor: '#F9906F',
    icon: meatlevelicon,
    key: 'meat_cook_level',
  },
  eggLevels: {
    name: 'Egg Level',
    levels: [
      'Raw',
      'Boiled',
      'Sunny Side Up',
      'Scrambled',
      'Omelette',
      'Well Done',
    ],
    minimumColor: '#7B10B8',
    maximumColor: '#7B10B8',
    icon: egglevelicon,
    key: 'egg_cook_level',
  },
};

function CustomisationPersonalisation(props) {
  const [value, setValue] = useState(props?.item?.customizable ? 0 : 1);
  const [customizationData, setCustomizationData] = useState([]);
  const [cartData, setCardData] = useState({});
  const [selectedSizeOPtion, setSelectedSizeOption] = useState();
  const [showPersonalization, setShowPersonalization] = useState(false);
  const [spiceLevel, setShowSpiceLevel] = useState({
    status: false,
    level: 1,
  });
  const [saltLevel, setShowSaltLevel] = useState(false);
  const [sugarLevel, setShowSugarLevel] = useState(false);
  const [fatLevel, setShowFatLevel] = useState(false);
  const [eggLevel, setShowEggLevel] = useState(false);
  const [meatLevel, setShowMeatLevel] = useState(false);
  const [countAddon, setCountAddon] = useState({
    presentCount: 0,
    finalCount: 0,
  });
  const [activeTab, setActiveTab] = useState(
    props?.item?.customizable
      ? 'Customization'
      : props?.item?.[props?.orderSelected]?.sizes?.length > 1
      ? 'Size Option'
      : props?.item?.personalization_status
      ? 'Personalization'
      : 'Customization'
  );

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (newCheckedState) => {
    setIsChecked(newCheckedState);
  };

  const onChangeAddOn = (
    optionsData,
    addOnId,
    optionsId,
    checkedStatus,
    isFree
  ) => {
    // changes checked status
    let optionsDataTwoActive = optionsData;
    let activeCheckedData = {};
    let actualStateData = JSON.parse(JSON.stringify(customizationData));
    let updatedChecked = [];

    for (let i = 0; i < actualStateData.addOns.length; i++) {
      if (actualStateData.addOns[i]._id === addOnId) {
        let tempAddOn = {};
        tempAddOn = JSON.parse(JSON.stringify(actualStateData.addOns[i]));
        tempAddOn.options = [];
        for (let j = 0; j < actualStateData.addOns[i].options.length; j++) {
          if (actualStateData.addOns[i].options[j]._id === optionsId) {
            let tempChecked = actualStateData.addOns[i].options[j];
            if (checkedStatus === undefined) {
              tempChecked.checked = true;
              tempAddOn.options.push(tempChecked);
            } else if (checkedStatus) {
              tempChecked.checked = false;
              tempAddOn.options.push(tempChecked);
            } else {
              tempChecked.checked = true;
              tempAddOn.options.push(tempChecked);
            }
          } else {
            tempAddOn.options.push(actualStateData.addOns[i].options[j]);
          }
        }
        updatedChecked.push(tempAddOn);
        activeCheckedData = tempAddOn.options.filter((item, index) => {
          return item._id === optionsDataTwoActive._id;
        });
      } else {
        updatedChecked.push(actualStateData.addOns[i]);
      }
    }

    actualStateData.addOns = updatedChecked;
    setCustomizationData(actualStateData);

    let checkStatus = document.getElementById(optionsId).checked;
    let actualData = JSON.parse(JSON.stringify(cartData));

    let foundAddOnId = false;
    for (let i = 0; i < cartData.order_items[0].addOns.length; i++) {
      if (addOnId === cartData.order_items[0].addOns[i]._id) {
        foundAddOnId = true;

        if (checkStatus) {
          let data = cartData.order_items[0].addOns[i];
          // data.options.push(optionsData);
          data.options.push(...activeCheckedData);
          actualData.order_items[0].addOns[i] = data;
          if (!isFree) {
            actualData.order_items[0].finalPrice =
              actualData.order_items[0].finalPrice +
              parseInt(optionsData?.price);
            actualData.order_items[0].allAddonsTotal =
              actualData.order_items[0].allAddonsTotal +
              parseInt(optionsData?.price);
          }
        } else {
          let removeAddon = actualData.order_items[0].addOns[i].options.filter(
            (item) => {
              return optionsId !== item._id;
            }
          );
          if (removeAddon.length === 0) {
            //actualData.order_items[0].addOns[i].options = removeAddon;

            actualData.order_items[0].addOns.splice(i, 1);
          } else {
            actualData.order_items[0].addOns[i].options = removeAddon;
          }
          if (!isFree) {
            actualData.order_items[0].finalPrice =
              actualData.order_items[0].finalPrice -
              parseInt(optionsData?.price);
            actualData.order_items[0].allAddonsTotal =
              actualData.order_items[0].allAddonsTotal -
              parseInt(optionsData?.price);
          }
        }
      }
    }

    if (!foundAddOnId) {
      let data = {};
      data._id = addOnId;
      data.options = [];
      // data.options.push(optionsData);
      data.options.push(...activeCheckedData);
      if (!isFree) {
        actualData.order_items[0].finalPrice =
          actualData.order_items[0].finalPrice + parseInt(optionsData?.price);
        actualData.order_items[0].allAddonsTotal =
          actualData.order_items[0].allAddonsTotal +
          parseInt(optionsData?.price);
        actualData.order_items[0].addOns.push(data);
      }
    }

    setCardData(actualData);
  };

  const onChangeMultipleAddOn = (
    optionsData,
    addOnId,
    optionsId,
    multipleId,
    checkedStatus,
    optionsData2,
    isFree
  ) => {
    let optionsDataTwoActive = optionsData2;
    let activeCheckedData = {};
    let actualStateData = JSON.parse(JSON.stringify(customizationData));
    let updatedChecked = [];
    for (let i = 0; i < actualStateData.addOns.length; i++) {
      if (actualStateData.addOns[i]._id === addOnId) {
        let tempAddOn = {};
        tempAddOn = JSON.parse(JSON.stringify(actualStateData.addOns[i]));
        tempAddOn.options = [];
        for (let j = 0; j < actualStateData.addOns[i].options.length; j++) {
          if (actualStateData.addOns[i].options[j]._id === optionsId) {
            let tempAddOnOne = {};
            tempAddOnOne = JSON.parse(
              JSON.stringify(actualStateData.addOns[i].options[j])
            );
            tempAddOnOne.multipleSize = [];
            for (
              let k = 0;
              k < actualStateData.addOns[i].options[j].multipleSize.length;
              k++
            ) {
              actualStateData.addOns[i].options[j].multipleSize[
                k
              ].checked = false;
              if (
                actualStateData.addOns[i].options[j].multipleSize[k]._id ===
                multipleId
              ) {
                let tempChecked =
                  actualStateData.addOns[i].options[j].multipleSize[k];
                if (checkedStatus === undefined) {
                  tempChecked.checked = true;
                  tempAddOnOne.multipleSize.push(tempChecked);
                } else if (checkedStatus) {
                  tempChecked.checked = false;
                  tempAddOnOne.multipleSize.push(tempChecked);
                } else {
                  tempChecked.checked = true;
                  tempAddOnOne.multipleSize.push(tempChecked);
                }
              } else {
                tempAddOnOne.multipleSize.push(
                  actualStateData.addOns[i].options[j].multipleSize[k]
                );
              }
            }
            tempAddOn.options.push(tempAddOnOne);
          } else {
            tempAddOn.options.push(actualStateData.addOns[i].options[j]);
          }
        }
        updatedChecked.push(tempAddOn);
        activeCheckedData = tempAddOn.options.filter((item, index) => {
          return item._id === optionsDataTwoActive._id;
        });
      } else {
        updatedChecked.push(actualStateData.addOns[i]);
      }
    }
    actualStateData.addOns = updatedChecked;
    setCustomizationData(actualStateData);
    let checkStatus = document.getElementById(multipleId).checked;
    let actualData = JSON.parse(JSON.stringify(cartData));
    let foundAddOnId = false;

    // debugger;
    for (let i = 0; i < cartData.order_items[0].addOns.length; i++) {
      if (addOnId === cartData.order_items[0].addOns[i]._id) {
        foundAddOnId = true;

        if (checkStatus === undefined) {
          let data = cartData.order_items[0].addOns[i];
          data.options = data.options.filter((e) => {
            e.multipleSize.map((t) => {
              if (t.checked)
                actualData.order_items[0].finalPrice =
                  actualData.order_items[0].finalPrice - t.itemPrice;
            });
            return e?._id != optionsId;
          });
          data.options.push(...activeCheckedData);
          actualData.order_items[0].addOns[i] = data;
          if (!isFree) {
            actualData.order_items[0].finalPrice =
              actualData.order_items[0].finalPrice +
              parseInt(optionsData.itemPrice);
            actualData.order_items[0].allAddonsTotal =
              actualData.order_items[0].allAddonsTotal +
              parseInt(optionsData.itemPrice);
          }
        } else if (checkStatus) {
          let data = cartData.order_items[0].addOns[i];
          data.options = data.options.filter((e) => {
            if (e?._id == optionsId) {
              e.multipleSize.map((t) => {
                if (t.checked)
                  actualData.order_items[0].finalPrice =
                    actualData.order_items[0].finalPrice - t.itemPrice;
              });
            }
            return e?._id != optionsId;
          });
          data.options.push(...activeCheckedData);
          actualData.order_items[0].addOns[i] = data;
          if (!isFree) {
            actualData.order_items[0].finalPrice =
              actualData.order_items[0].finalPrice +
              parseInt(optionsData.itemPrice);
            actualData.order_items[0].allAddonsTotal =
              actualData.order_items[0].allAddonsTotal +
              parseInt(optionsData.itemPrice);
          }
        } else {
          let removeAddon = actualData.order_items[0].addOns[i].options.filter(
            (item) => {
              return optionsId !== item._id;
            }
          );
          if (removeAddon.length === 0) {
            actualData.order_items[0].addOns.splice(i, 1);
          } else {
            actualData.order_items[0].addOns[i].options = removeAddon;
          }
          if (!isFree) {
            actualData.order_items[0].finalPrice =
              actualData.order_items[0].finalPrice -
              parseInt(optionsData.itemPrice);
            actualData.order_items[0].allAddonsTotal =
              actualData.order_items[0].allAddonsTotal -
              parseInt(optionsData.itemPrice);
          }
        }
      }
    }

    if (!foundAddOnId) {
      let data = {};
      data._id = addOnId;
      data.options = [];
      data.options.push(...activeCheckedData);
      if (!isFree) {
        actualData.order_items[0].finalPrice =
          actualData.order_items[0].finalPrice +
          parseInt(optionsData.itemPrice);
        actualData.order_items[0].allAddonsTotal =
          actualData.order_items[0].allAddonsTotal +
          parseInt(optionsData.itemPrice);
        actualData.order_items[0].addOns.push(data);
      }
    }

    setCountAddon({
      ...countAddon,
      presentCount: countAddon.presentCount + 1,
    });
    setCardData(actualData);
  };

  useEffect(() => {
    setCustomizationData(props.item);

    let prepareCartData = {
      order_items: [
        {
          item: {},
          size: {},
          customised_details: {},
          customised: false,
          quantity: 1,
          addOns: [],
          finalPrice: 0,
          allAddonsTotal: 0,
          notes: '',
          incase_of_unavailable: '',
          sur_charges: [],
          charges: 0,
          counter_number: '',
          category: '',
        },
      ],
    };

    prepareCartData.order_items[0].category = props?.item?.category || '';
    prepareCartData.order_items[0].item.images = props?.item?.images || '';
    prepareCartData.order_items[0].item.prepTime = props?.item?.prepTime || '';
    prepareCartData.order_items[0].item.counter_number =
      props?.item?.counter_number || 0;
    prepareCartData.order_items[0].item.auto_accept =
      props?.item?.auto_accept || '';
    prepareCartData.order_items[0].item.veg = props?.item?.veg || false;
    prepareCartData.order_items[0].item.itemType = props?.item?.itemType || '';
    prepareCartData.order_items[0].item.name = props?.item?.name || '';
    prepareCartData.order_items[0].item.category = props?.item?.category || '';
    prepareCartData.order_items[0].item.description =
      props?.item?.description || '';
    prepareCartData.order_items[0].item.ingredient_description =
      props?.item?.ingredient_description || '';
    prepareCartData.order_items[0].item.keywords = props?.item?.keywords || '';
    prepareCartData.order_items[0].item.id = props?.item?._id || '';
    prepareCartData.order_items[0].veg = props?.item?.veg || '';
    prepareCartData.order_items[0].itemType = props?.item?.itemType || '';
    prepareCartData.order_items[0].sur_charges =
      props?.item?.[props.orderSelected]?.sur_charges || [];

    if (props?.item?.[props.orderSelected]?.sizes) {
      for (let i = 0; i < props.item[props.orderSelected].sizes.length; i++) {
        if (props.item[props.orderSelected].sizes[i].isDefault) {
          setSelectedSizeOption(props.item[props.orderSelected].sizes[i]);
          prepareCartData.order_items[0].finalPrice =
            props.item[props.orderSelected].sizes[i]?.price;
          prepareCartData.order_items[0].size =
            props.item[props.orderSelected].sizes[i];
        }
      }
    }

    if (props.item && props.item.personalization_status) {
      setShowPersonalization(true);
      prepareCartData.order_items[0].customised_details.customised = true;

      if (props.item.spice_level.value) {
        setShowSpiceLevel({
          status: true,
          level: props.item.spice_level.level,
        });

        prepareCartData.order_items[0].customised_details.spice_level = {};
        prepareCartData.order_items[0].customised_details.spice_level = {
          level: props.item.spice_level.level,
        };
      }
      if (props.item.salt_level.value) {
        setShowSaltLevel({
          status: true,
          level: props.item.salt_level.level,
        });
        prepareCartData.order_items[0].customised_details.salt_level = {};
        prepareCartData.order_items[0].customised_details.salt_level = {
          level: props.item.salt_level.level,
        };
      }
      if (props.item.sugar_level.value) {
        setShowSugarLevel({
          status: true,
          level: props.item.sugar_level.level,
        });
        prepareCartData.order_items[0].customised_details.sugar_level = {};
        prepareCartData.order_items[0].customised_details.sugar_level = {
          level: props.item.sugar_level.level,
        };
      }
      if (props.item.fat_level.value) {
        setShowFatLevel({
          status: true,
          level: props.item.fat_level.level,
        });
        prepareCartData.order_items[0].customised_details.fat_level = {};
        prepareCartData.order_items[0].customised_details.fat_level = {
          level: props.item.fat_level.level,
        };
      }
      if (props.item.meat_cook_level.value) {
        setShowMeatLevel({
          status: true,
          level: props.item.meat_cook_level.level,
        });
        prepareCartData.order_items[0].customised_details.meat_cook_level = {};
        prepareCartData.order_items[0].customised_details.meat_cook_level = {
          level: props.item.meat_cook_level.level,
        };
      }
      if (props.item.egg_cook_level.value) {
        setShowEggLevel({
          status: true,
          level: props.item.egg_cook_level.level,
        });
        prepareCartData.order_items[0].customised_details.egg_cook_level = {};
        prepareCartData.order_items[0].customised_details.egg_cook_level = {
          level: props.item.egg_cook_level.level,
        };
      }
    }
    setCardData(prepareCartData);
  }, [props.item]);

  const onChangeLevel = (e, value, type) => {
    if (type === 'spice_level') {
      setShowSpiceLevel({
        ...spiceLevel,
        level: value,
      });
    } else if (type === 'salt_level') {
      setShowSaltLevel({
        ...saltLevel,
        level: value,
      });
    } else if (type === 'sugar_level') {
      setShowSugarLevel({
        ...sugarLevel,
        level: value,
      });
    } else if (type === 'fat_level') {
      setShowFatLevel({
        ...fatLevel,
        level: value,
      });
    } else if (type === 'meat_cook_level') {
      setShowMeatLevel({
        ...meatLevel,
        level: value,
      });
    } else if (type === 'egg_cook_level') {
      setShowEggLevel({
        ...eggLevel,
        level: value,
      });
    }

    let actualData = JSON.parse(JSON.stringify(cartData));

    actualData.order_items[0].customised_details[type] = { level: value };

    setCardData(actualData);
  };

  const onChangeSize = (indexId) => {
    let data = JSON.parse(JSON.stringify(customizationData));

    let defaultStatus = data[props.orderSelected]?.sizes?.map((item, index) => {
      if (index === indexId) {
        let updateCartData = JSON.parse(JSON.stringify(cartData));
        setCountAddon({
          ...countAddon,
          finalCount: parseInt(item?.freeOptions),
          presentCount: 0,
        });
        updateCartData.order_items[0].size = item;
        updateCartData.order_items[0].freeOptions = item?.freeOptions;
        updateCartData.order_items[0].finalPrice =
          item?.price + updateCartData.order_items[0].allAddonsTotal;
        setCardData(updateCartData);
        setSelectedSizeOption(item);
        return { ...item, isDefault: true };
      } else {
        return { ...item, isDefault: false };
      }
    });

    data[props.orderSelected].sizes = defaultStatus;
    setCustomizationData(data);
  };
  const onCloseModal = () => {
    props?.setOpen(false);
  };

  const addItemToCart = () => {
    let actualCartData = JSON.parse(JSON.stringify(cartData));
    actualCartData.order_items[0].itemId = props.item._id;
    actualCartData.order_items[0].customised =
      props.item.customizable !== undefined && props.item.customizable !== null
        ? props.item.customizable
        : false;
    let actualTotal = cartData.order_items[0].finalPrice;
    let charges = 0;

    for (let i = 0; i < cartData.order_items[0].sur_charges.length; i++) {
      if (cartData.order_items[0].sur_charges[i].flag === 'VALUE') {
        charges =
          charges + parseFloat(cartData.order_items[0].sur_charges[i].value);
      } else {
        let percent =
          (actualTotal *
            parseFloat(cartData.order_items[0].sur_charges[i].value)) /
          100;
        charges = charges + percent;
      }
    }
    actualCartData.order_items[0].charges = charges;
    actualCartData.order_items[0].selectedOrder = props?.orderSelected;

    // let cartQuantity = 1;

    // let count = 0;

    // let exisTingData = [...props.cart_data];

    // for (let i = 0; i < exisTingData.length; i++) {
    //   if (exisTingData[i][0].size._id === cartData.order_items[0].size._id) {
    //     count = count + exisTingData[i][0].quantity;
    //     //props.incrementHandler(cartQuantity);
    //   }
    // }

    // if (count > 0) {
    //   props.onClickContinue(cartQuantity + count);
    // } else {
    //   props.onClickContinue(cartQuantity);
    // }
    props.onClickContinue(actualCartData);
    onCloseModal();
    // dispatch({
    //   type: SET_VENDOR_ORDERING_CART_DATA,
    //   payload: actualCartData,
    // });
  };

  return (
    <div className="personalisationcustomisation">
      <Modal show={props?.open} size="md" onHide={onCloseModal} centered>
        <Modal.Header closeButton>
          <div className="personalisationcustomisation">
            <HeaderPersonalizationCustomization
              leftIconPress={onCloseModal}
              title={props?.item?.name}
              rating={
                props?.item?.rating !== undefined ? props?.item?.rating : 0
              }
              reviews={
                props?.item?.reviews !== undefined ? props?.item?.reviews : 0
              }
              veg={props?.item?.itemType}
            />
          </div>
        </Modal.Header>
        <hr className="m-0 p-0" />

        <Modal.Body className="px-0">
          <div className="personalisationcustomisation">
            <TabComponent
              tabs={[
                activeTab === 'Customization' ? 'Customization' : 'Size Option',
                'Personalization',
              ]}
              initialTab={activeTab}
              onTabChange={handleTabChange}
              RestaurantInfo={false}
            />
            <div
              style={{ backgroundColor: '#E7E7FF', padding: 20 }}
              className="f12-700 px-2"
            >
              {props?.item?.ingredient_description}
            </div>

            {/* <div className="row " style={{ backgroundColor: '#FFFBEE' }}>
            <Col md={2} xs={2}>
              <img
                src={
                  (customizationData &&
                    customizationData.images &&
                    customizationData.images[0]) ||
                  NOTAVAILABLEIMAGE
                }
                width="60px"
                alt={customizationData && customizationData?.name}
                title={customizationData && customizationData?.name}
              />
            </Col>

            <Col md={10} xs={10}>
              <div className="f16-500">
                {ITEMTYPEVEG.map((type, index) => {
                  return (
                    <React.Fragment key={index}>
                      {customizationData?.itemType === type?.value ? (
                        <Image
                          width="15px"
                          height="15px"
                          src={type.url}
                          alt={type.title}
                          title={type.title}
                          fluid
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })}{' '}
                &nbsp;&nbsp;
                {customizationData.name}
              </div>
              <div
                style={{
                  position: 'relative',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  top: '2%',
                  left: '5%',
                }}
                className="badge badge-dark bottom-left"
              >
                <img
                  src={require('../../../assets/images/IconsandImages/warningstar.png')}
                  width="10px"
                  heigth="10px"
                  alt="Star"
                />
                {props.item.rating}
              </div>
            </Col>
          </div> */}
            {/* <div
            className="row "
            style={{
              backgroundColor: '#F3F3F3',
              marginTop: '2%',
              width: '100%',
              padding: '5px 15px 5px 15px',
            }}
          >
            {customizationData.description}
          </div> */}
            <Container fluid className="add-customization-order-processing">
              <div>
                {(activeTab === 'Customization' ||
                  activeTab === 'Size Option') && (
                  <div style={{ marginTop: '3%' }}>
                    <div className="f16-700">Size</div>
                    {customizationData &&
                    customizationData[props.orderSelected] &&
                    customizationData[props.orderSelected].sizes !==
                      undefined &&
                    customizationData &&
                    customizationData[props.orderSelected] &&
                    customizationData[props.orderSelected].sizes !== null ? (
                      <div className="f12-500">
                        Please select your{props?.item?.name} size
                      </div>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col md={12} className="mt-2">
                        <FormControl
                          component="fieldset"
                          style={{ width: '100%' }}
                        >
                          <RadioGroup aria-label="Size" name="Size">
                            {customizationData &&
                              customizationData[props.orderSelected] &&
                              customizationData[
                                props.orderSelected
                              ]?.sizes?.map((item, index) => (
                                <Row
                                  key={index}
                                  className="d-flex align-items-center"
                                >
                                  <Col xs={8} className="f14-700">
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          sx={{
                                            '&.Mui-checked': {
                                              color: 'red',
                                            },
                                          }}
                                        />
                                      }
                                      label={item.description}
                                      checked={item.isDefault}
                                      onChange={() => onChangeSize(index)}
                                    />
                                  </Col>
                                  <Col xs={4} align="right" className="f14-700">
                                    ₹ {item?.price}
                                  </Col>
                                </Row>
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </Col>
                    </Row>
                    <hr />

                    <div className="pl-2">
                      {props.item?.customizable
                        ? customizationData &&
                          customizationData.addOns &&
                          customizationData.addOns.map((item, index) => (
                            <CustomizationIndividual
                              customizationDataMain={props.item}
                              customizationData={customizationData}
                              selectedSizeOPtion={selectedSizeOPtion}
                              onChangeMultipleAddOn={onChangeMultipleAddOn}
                              onChangeAddOn={onChangeAddOn}
                              item={item}
                            />
                          ))
                        : null}
                    </div>

                    <div style={{ paddingBottom: 10 }}>
                      <CheckBoxWithText
                        labelText="Check this box"
                        onChange={handleCheckboxChange}
                        isselected={isChecked}
                      />
                    </div>
                  </div>
                )}
                {activeTab === 'Personalization' && (
                  <div>
                    <div style={{ padding: 5 }} className="f18-700 my-3">
                      {'Personalize'}
                    </div>
                    {spiceLevel.status ? (
                      <>
                        <div>
                          <Col xl={12}>
                            <div className="w-100 mb-4 spice">
                              <div
                                className="levelContainer"
                                style={{
                                  borderColor:
                                    levelsdata.spiceLevels.maximumColor,
                                }}
                              >
                                <span
                                  className="f14-700"
                                  style={{ paddingRight: 5 }}
                                >
                                  {levelsdata.spiceLevels.name}
                                </span>
                                <img
                                  src={levelsdata.spiceLevels.icon}
                                  style={{
                                    height: '20px',
                                    width: '17px',
                                    resizeMode: 'contain',
                                  }}
                                  alt=""
                                />
                              </div>
                              <Slider
                                min={0}
                                max={4}
                                name="spice_level.level"
                                value={spiceLevel.level}
                                step={1}
                                onChange={(e, value) =>
                                  onChangeLevel(e, value, 'spice_level')
                                }
                                disabled={!props?.item?.spice_level?.customized}
                                sx={{
                                  '& .MuiSlider-mark': {
                                    display: 'none', // Hide the marks
                                  },
                                  '& .MuiSlider-thumb': {
                                    color: levelsdata.spiceLevels.maximumColor, // Thumb color
                                    height: 16, // Increase thumb height
                                    width: 16, // Increase thumb width to keep it circular
                                    '&:hover, &.Mui-focusVisible': {
                                      boxShadow:
                                        '0px 0px 0px 8px rgba(255, 105, 135, 0.16)',
                                    },
                                    '&.Mui-active': {
                                      boxShadow:
                                        '0px 0px 0px 14px rgba(255, 105, 135, 0.16)',
                                    },
                                  },

                                  '& .MuiSlider-track': {
                                    color: levelsdata.spiceLevels.maximumColor,
                                    height: 7,
                                    // Track color before the thumb
                                  },
                                  '& .MuiSlider-rail': {
                                    color: levelsdata.spiceLevels.minimumColor,
                                    height: 7, // Track color after the thumb
                                  },
                                }}
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="f10-400">No Spice</div>
                                <div className="f10-400">Mild</div>
                                <div className="f10-400">Medium</div>
                                <div className="f10-400">Hot</div>
                                <div className="f10-400">Very Hot</div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}

                    {saltLevel.status ? (
                      <>
                        <div>
                          <Col xl={12}>
                            <div className="w-100 mb-4 spice">
                              <div
                                className="levelContainer"
                                style={{
                                  borderColor:
                                    levelsdata.saltLevels.maximumColor,
                                }}
                              >
                                <span
                                  className="f14-700"
                                  style={{ paddingRight: 5 }}
                                >
                                  {levelsdata.saltLevels.name}
                                </span>
                                <img
                                  src={levelsdata.saltLevels.icon}
                                  style={{
                                    height: '20px',
                                    width: '17px',
                                    resizeMode: 'contain',
                                  }}
                                  alt=""
                                />
                              </div>
                              <Slider
                                min={0}
                                max={5}
                                name="salt_level.level"
                                value={saltLevel.level}
                                step={1}
                                onChange={(e, value) =>
                                  onChangeLevel(e, value, 'salt_level')
                                }
                                disabled={!props?.item?.salt_level?.customized}
                                sx={{
                                  '& .MuiSlider-mark': {
                                    display: 'none', // Hide the marks
                                  },
                                  '& .MuiSlider-thumb': {
                                    color: levelsdata.saltLevels.maximumColor, // Thumb color
                                    height: 16, // Increase thumb height
                                    width: 16, // Increase thumb width to keep it circular
                                    '&:hover, &.Mui-focusVisible': {
                                      boxShadow:
                                        '0px 0px 0px 8px rgba(255, 105, 135, 0.16)',
                                    },
                                    '&.Mui-active': {
                                      boxShadow:
                                        '0px 0px 0px 14px rgba(255, 105, 135, 0.16)',
                                    },
                                  },

                                  '& .MuiSlider-track': {
                                    color: levelsdata.saltLevels.maximumColor,
                                    height: 7,
                                    // Track color before the thumb
                                  },
                                  '& .MuiSlider-rail': {
                                    color: levelsdata.saltLevels.minimumColor,
                                    height: 7, // Track color after the thumb
                                  },
                                }}
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="f10-400">No Salt</div>
                                <div className="f10-400">Low</div>
                                <div className="f10-400">Normal</div>
                                <div className="f10-400">Medium</div>
                                <div className="f10-400">Salty</div>
                                <div className="f10-400">Very Salty</div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}

                    {sugarLevel.status ? (
                      <>
                        <div>
                          <Col xl={12}>
                            <div className="w-100 mb-4 suger">
                              <div
                                className="levelContainer"
                                style={{
                                  borderColor:
                                    levelsdata.sugarLevels.maximumColor,
                                }}
                              >
                                <span
                                  className="f14-700"
                                  style={{ paddingRight: 5 }}
                                >
                                  {levelsdata.sugarLevels.name}
                                </span>
                                <img
                                  src={levelsdata.sugarLevels.icon}
                                  style={{
                                    height: '20px',
                                    width: '17px',
                                    resizeMode: 'contain',
                                  }}
                                  alt=""
                                />
                              </div>
                              <Slider
                                min={0}
                                max={5}
                                name="salt_level.level"
                                value={sugarLevel.level}
                                step={1}
                                onChange={(e, value) =>
                                  onChangeLevel(e, value, 'sugar_level')
                                }
                                disabled={!props?.item?.sugar_level?.customized}
                                sx={{
                                  '& .MuiSlider-mark': {
                                    display: 'none', // Hide the marks
                                  },
                                  '& .MuiSlider-thumb': {
                                    color: levelsdata.sugarLevels.maximumColor, // Thumb color
                                    height: 16, // Increase thumb height
                                    width: 16, // Increase thumb width to keep it circular
                                    '&:hover, &.Mui-focusVisible': {
                                      boxShadow:
                                        '0px 0px 0px 8px rgba(255, 105, 135, 0.16)',
                                    },
                                    '&.Mui-active': {
                                      boxShadow:
                                        '0px 0px 0px 14px rgba(255, 105, 135, 0.16)',
                                    },
                                  },

                                  '& .MuiSlider-track': {
                                    color: levelsdata.sugarLevels.maximumColor,
                                    height: 7,
                                    // Track color before the thumb
                                  },
                                  '& .MuiSlider-rail': {
                                    color: levelsdata.sugarLevels.minimumColor,
                                    height: 7, // Track color after the thumb
                                  },
                                }}
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="f10-400">No Sugar</div>
                                <div className="f10-400">Low</div>
                                <div className="f10-400">Normal</div>
                                <div className="f10-400">Medium</div>
                                <div className="f10-400">Sweet</div>
                                <div className="f10-400">Very Sweet</div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}

                    {fatLevel.status ? (
                      <>
                        <div>
                          <Col xl={12}>
                            <div className="w-100 mb-4 fat">
                              <div
                                className="levelContainer"
                                style={{
                                  borderColor:
                                    levelsdata.oilLevels.maximumColor,
                                }}
                              >
                                <span
                                  className="f14-700"
                                  style={{ paddingRight: 5 }}
                                >
                                  {levelsdata.oilLevels.name}
                                </span>
                                <img
                                  src={levelsdata.oilLevels.icon}
                                  style={{
                                    height: '20px',
                                    width: '17px',
                                    resizeMode: 'contain',
                                  }}
                                  alt=""
                                />
                              </div>
                              <Slider
                                min={0}
                                max={5}
                                name="salt_level.level"
                                value={fatLevel.level}
                                step={1}
                                onChange={(e, value) =>
                                  onChangeLevel(e, value, 'fat_level')
                                }
                                disabled={!props?.item?.fat_level?.customized}
                                sx={{
                                  '& .MuiSlider-mark': {
                                    display: 'none', // Hide the marks
                                  },
                                  '& .MuiSlider-thumb': {
                                    color: levelsdata.oilLevels.maximumColor, // Thumb color
                                    height: 16, // Increase thumb height
                                    width: 16, // Increase thumb width to keep it circular
                                    '&:hover, &.Mui-focusVisible': {
                                      boxShadow:
                                        '0px 0px 0px 8px rgba(255, 105, 135, 0.16)',
                                    },
                                    '&.Mui-active': {
                                      boxShadow:
                                        '0px 0px 0px 14px rgba(255, 105, 135, 0.16)',
                                    },
                                  },

                                  '& .MuiSlider-track': {
                                    color: levelsdata.oilLevels.maximumColor,
                                    height: 7,
                                    // Track color before the thumb
                                  },
                                  '& .MuiSlider-rail': {
                                    color: levelsdata.oilLevels.minimumColor,
                                    height: 7, // Track color after the thumb
                                  },
                                }}
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="f10-400">No Oil</div>
                                <div className="f10-400">Low</div>
                                <div className="f10-400">Normal</div>
                                <div className="f10-400">Medium</div>
                                <div className="f10-400">Regular</div>
                                <div className="f10-400">Extra</div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}

                    {meatLevel.status ? (
                      <>
                        <div>
                          <Col xl={12}>
                            <div className="w-100 mb-4 meat">
                              <div
                                className="levelContainer"
                                style={{
                                  borderColor:
                                    levelsdata.meatLevels.maximumColor,
                                }}
                              >
                                <span
                                  className="f14-700"
                                  style={{ paddingRight: 5 }}
                                >
                                  {levelsdata.meatLevels.name}
                                </span>
                                <img
                                  src={levelsdata.meatLevels.icon}
                                  style={{
                                    height: '20px',
                                    width: '17px',
                                    resizeMode: 'contain',
                                  }}
                                  alt=""
                                />
                              </div>
                              <Slider
                                min={0}
                                max={5}
                                name="salt_level.level"
                                value={meatLevel.level}
                                step={1}
                                onChange={(e, value) =>
                                  onChangeLevel(e, value, 'meat_cook_level')
                                }
                                disabled={
                                  !props?.item?.meat_cook_level?.customized
                                }
                                sx={{
                                  '& .MuiSlider-mark': {
                                    display: 'none', // Hide the marks
                                  },
                                  '& .MuiSlider-thumb': {
                                    color: levelsdata.meatLevels.maximumColor, // Thumb color
                                    height: 16, // Increase thumb height
                                    width: 16, // Increase thumb width to keep it circular
                                    '&:hover, &.Mui-focusVisible': {
                                      boxShadow:
                                        '0px 0px 0px 8px rgba(255, 105, 135, 0.16)',
                                    },
                                    '&.Mui-active': {
                                      boxShadow:
                                        '0px 0px 0px 14px rgba(255, 105, 135, 0.16)',
                                    },
                                  },

                                  '& .MuiSlider-track': {
                                    color: levelsdata.meatLevels.maximumColor,
                                    height: 7,
                                    // Track color before the thumb
                                  },
                                  '& .MuiSlider-rail': {
                                    color: levelsdata.meatLevels.minimumColor,
                                    height: 7, // Track color after the thumb
                                  },
                                }}
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="f10-400">Raw</div>
                                <div className="f10-400">Rare</div>
                                <div className="f10-400">Medium Rare</div>
                                <div className="f10-400">Medium</div>
                                <div className="f10-400">Regular</div>
                                <div className="f10-400">Well Done</div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}

                    {eggLevel.status ? (
                      <>
                        <div>
                          <Col xl={12}>
                            <div className="w-100 mb-4 egg">
                              <div
                                className="levelContainer"
                                style={{
                                  borderColor:
                                    levelsdata.eggLevels.maximumColor,
                                }}
                              >
                                <span
                                  className="f14-700"
                                  style={{ paddingRight: 5 }}
                                >
                                  {levelsdata.eggLevels.name}
                                </span>
                                <img
                                  src={levelsdata.eggLevels.icon}
                                  style={{
                                    height: '20px',
                                    width: '17px',
                                    resizeMode: 'contain',
                                  }}
                                  alt=""
                                />
                              </div>
                              <Slider
                                min={0}
                                max={5}
                                name="salt_level.level"
                                value={eggLevel.level}
                                step={1}
                                onChange={(e, value) =>
                                  onChangeLevel(e, value, 'egg_cook_level')
                                }
                                disabled={
                                  !props?.item?.egg_cook_level?.customized
                                }
                                sx={{
                                  '& .MuiSlider-mark': {
                                    display: 'none', // Hide the marks
                                  },
                                  '& .MuiSlider-thumb': {
                                    color: levelsdata.eggLevels.maximumColor, // Thumb color
                                    height: 16, // Increase thumb height
                                    width: 16, // Increase thumb width to keep it circular
                                    '&:hover, &.Mui-focusVisible': {
                                      boxShadow:
                                        '0px 0px 0px 8px rgba(255, 105, 135, 0.16)',
                                    },
                                    '&.Mui-active': {
                                      boxShadow:
                                        '0px 0px 0px 14px rgba(255, 105, 135, 0.16)',
                                    },
                                  },

                                  '& .MuiSlider-track': {
                                    color: levelsdata.eggLevels.maximumColor,
                                    height: 7,
                                    // Track color before the thumb
                                  },
                                  '& .MuiSlider-rail': {
                                    color: levelsdata.eggLevels.minimumColor,
                                    height: 7, // Track color after the thumb
                                  },
                                }}
                              />
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="f10-400">Raw</div>
                                <div className="f10-400">Boiled</div>
                                <div className="f10-400">Sunny Side Up</div>
                                <div className="f10-400">Scrambled</div>
                                <div className="f10-400">Omelette</div>
                                <div className="f10-400">Well Done</div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </>
                    ) : (
                      <> </>
                    )}
                    <div style={{ paddingBottom: 10 }} className="f18-700">
                      {'Add Note'}
                    </div>
                    <div className="cardShadow">
                      <div className="cardContainer">
                        <textarea
                          name="occasion"
                          id="occasion"
                          placeholder="Any special instructions"
                          value={cartData?.notes}
                          maxLength={250}
                          onChange={(e) => {
                            setCardData((prevFinalData) => ({
                              ...prevFinalData,
                              order_items: [
                                {
                                  ...prevFinalData.order_items[0],
                                  notes: e.target.value,
                                },
                              ],
                            }));
                          }}
                          className="textInput"
                        />
                      </div>
                    </div>
                    <hr />
                    <div style={{ paddingBottom: 10 }}>
                      <CheckBoxWithText
                        labelText="Check this box"
                        onChange={handleCheckboxChange}
                        isselected={isChecked}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-4">
                    <b>
                      Total Cost <br />₹{' '}
                      {cartData &&
                        cartData.order_items &&
                        cartData.order_items[0] &&
                        cartData.order_items[0].finalPrice}
                    </b>
                  </div>
                  <div className="col-8">
                    {
                      <button
                        type="button"
                        className="btn btn-danger btn-lg btn btn-primary btn-large w-100"
                        style={{ color: 'white', fontSize: '14px' }}
                        onClick={() => addItemToCart()}
                      >
                        {value === 0 ? 'Continue' : 'Add to cart'}
                      </button>
                    }
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const CustomizationIndividual = ({
  customizationData,
  customizationDataMain,
  item,
  onChangeMultipleAddOn,
  onChangeAddOn,
}) => {
  const [optionCount, setOptionCount] = useState([]);
  return (
    <div>
      <Fragment>
        <h5 className="f14-700 mt-3">{item.name}</h5>
        <div style={{ fontWeight: '600', fontSize: 10, marginBottom: 10 }}>
          Please Select Your {customizationDataMain?.name} {item.name}
        </div>
        {/* <Row> */}
        {item.options.map((data, _index) => (
          <Fragment key={_index}>
            {!isEmpty(data?.multipleSize) && data?.multipleSize?.length > 0 ? (
              <div>
                <Accordion className="shadow-none rounded-0">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="px-2"
                  >
                    <p className="f12-700 m-0">{data.name}</p>
                  </AccordionSummary>
                  <AccordionDetails className="pt-0 d-block w-100">
                    {!isEmpty(data?.multipleSize) &&
                      data?.multipleSize.map((multiData, index) => {
                        return (
                          <div
                            className="py-1 w-100 d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            <div className="d-flex align-items-center amealio-radio-btn">
                              <input
                                id={multiData?._id}
                                name={data?._id}
                                type={'radio'}
                                value={multiData?._id}
                                className="mr-3"
                                onChange={(e) => {
                                  optionCount[_index] = e.target.value;
                                  setOptionCount(optionCount);
                                  let isFree = false;
                                  if (
                                    multiData?.checked &&
                                    item?.freeOptions >= optionCount?.length
                                  ) {
                                    isFree = true;
                                  } else if (
                                    item?.freeOptions > optionCount?.length
                                  ) {
                                    isFree = true;
                                  }
                                  onChangeMultipleAddOn(
                                    multiData,
                                    item?._id,
                                    data?._id,
                                    multiData?._id,
                                    multiData?.checked,
                                    data,
                                    isFree
                                  );
                                }}
                              />
                              <div className="f12-700 ml-1">
                                {multiData?.sizeDescription}
                              </div>
                            </div>
                            <div className="f12-700">
                              ₹ {multiData?.itemPrice}
                            </div>
                          </div>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <div
                className={`${
                  !isEmpty(data?.multipleSize) && !data?.multipleSize.length > 0
                    ? 'my-2 px-2'
                    : ''
                } d-flex mb-2 align-items-middle justify-content-between`}
              >
                <div className="d-flex align-items-center amealio-checkbox">
                  <input
                    id={data._id}
                    checked={data.checked}
                    type="checkbox"
                    className="mr-3"
                    style={{ color: data.checked ? 'red' : 'inherit' }}
                    onChange={() => {
                      console.log(
                        data.checked,
                        item?.freeOptions > optionCount?.length,
                        item?.freeOptions,
                        optionCount?.length
                      );
                      if (!optionCount.includes(data?._id)) {
                        setOptionCount([...optionCount, data?._id]);
                      } else {
                        let temp = optionCount.filter((e) => e != data?._id);
                        setOptionCount(temp);
                      }
                      let isFree = false;
                      if (
                        data?.checked &&
                        item?.freeOptions >= optionCount?.length
                      ) {
                        isFree = true;
                      } else if (item?.freeOptions > optionCount?.length) {
                        isFree = true;
                      }
                      onChangeAddOn(
                        data,
                        item._id,
                        data._id,
                        data.checked,
                        isFree
                      );
                    }}
                  />
                  <img
                    src={data?.veg ? vegIcon : nonVegIcon}
                    alt={data?.veg ? 'Veg' : 'Non-Veg'}
                    style={{
                      height: 13,
                      width: 13,
                      marginRight: 5,
                      borderWidth: 1,
                    }}
                  />
                  <div className="f12-700 ml-1">{data.name}</div>
                </div>
                <div className="f12-700">₹ {data.price}</div>
              </div>
            )}
          </Fragment>
        ))}
      </Fragment>
      <hr />
    </div>
  );
};

export default CustomisationPersonalisation;
