import React from 'react';
//import './PersonlizationCustomization.css';// Assuming you've moved styles to an external CSS file
import vegIcon from '../../../../assets/images/IconsandImages/vegindicator.png'; // Example path, replace with your actual image imports
import nonVegIcon from '../../../../assets/images/IconsandImages/nonveg_indicator.png';
import eggIcon from '../../../../assets/images/IconsandImages/egg.png';
import greenStarIcon from '../../../../assets/images/IconsandImages/greenStar.png';
import yellowStarIcon from '../../../../assets/images/IconsandImages/yellowStar.png';
import leftIcon from '../../../../assets/images/IconsandImages/backArrow.png'; // Replace with your actual left icon image import

const HeaderPersonalizationCustomization = ({
  leftIconName = leftIcon,
  leftIconPress,
  title,
  rating,
  reviews,
  veg,
}) => {
  const vegIconSrc = veg === 3 ? eggIcon : veg === 2 ? nonVegIcon : vegIcon;
  const ratingIconSrc = +rating > 3 ? greenStarIcon : yellowStarIcon;

  return (
    <div className="cnpcontainer">
      <div onClick={leftIconPress} className="icon-button pr-3">
        <img src={leftIconName} alt="Left Icon" className="left-icon" />
      </div>
      <div className="title-container">
        <div className="title-row">
          <img src={vegIconSrc} alt="Veg Icon" className="veg-icon" />
          {/* Replacing TypographyText with span */}
          <span className="f16-700">{title}</span>
        </div>

        <div className="rating-row">
          <div className="rating-container">
            <img
              src={ratingIconSrc}
              alt="Rating Star"
              className="rating-star"
            />
            {/* Inline styling can be replaced with className for consistency */}
            <span
              className="f12-700"
              style={{ color: '#707070' }}
            >{` ${rating}`}</span>
          </div>

          <span
            className="f12-700"
            style={{ color: '#50A2FF' }}
          >{`${reviews} Reviews`}</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderPersonalizationCustomization;
