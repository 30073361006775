import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useState } from 'react';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';

const ICONS = {
  RESTAURANT_OPEN: {
    experience: require('../../assets/images/landingPageAssets/experience.png'),
    walk_in: require('../../assets/images/landingPageAssets/walkin.png'),
    wait_list: require('../../assets/images/landingPageAssets/waitList.png'),
    reservation: require('../../assets/images/landingPageAssets/reservation.png'),
    dine_in: require('../../assets/images/landingPageAssets/dine_in_enabled.png'),
    take_away: require('../../assets/images/landingPageAssets/Takeaway_Enabled.png'),
    curb_side: require('../../assets/images/landingPageAssets/Curbside_Enabled.png'),
    skip_line: require('../../assets/images/landingPageAssets/skip_line.png'),
  },
  RESTAURANT_CLOSE: {
    experience: require('../../assets/images/landingPageAssets/expclosed.png'),
    walk_in: require('../../assets/images/landingPageAssets/walkinclosed.png'),
    wait_list: require('../../assets/images/landingPageAssets/waitlistclosed.png'),
    reservation: require('../../assets/images/landingPageAssets/reservationclosed.png'),
    dine_in: require('../../assets/images/landingPageAssets/Dine In _ Closed.png'),
    take_away: require('../../assets/images/landingPageAssets/Takeaway_Closed.png'),
    curb_side: require('../../assets/images/landingPageAssets/Curbside_Closed.png'),
    skip_line: require('../../assets/images/landingPageAssets/Skip the Line_Closed.png'),
  },
};

const Services = (props) => {
  const RestData = JSON.parse(sessionStorage.getItem('restaurantData'));
  const subscriptionData = RestData?.subscription?.casual_dining
    ? RestData?.subscription?.casual_dining_status
    : RestData?.subscription?.fast_food_dining
    ? RestData?.subscription?.fast_food_dining_status
    : RestData?.subscription?.multi_service_business
    ? RestData?.subscription?.multi_service_business_status
    : {};

  const [open_status, setOpenStatus] = useState({
    walk_in: false,
    wait_list: false,
    reservation: false,
    experience: false,
    curb_side: false,
    dine_in: false,
    take_away: false,
  });

  useEffect(() => {
    setOpenStatus({
      ...open_status,
      walk_in:
        RestData?.openStatus?.open && RestData?.seatingWaitingTime === '00-00'
          ? true
          : undefined,
      wait_list:
        RestData?.openStatus?.open && RestData?.seatingWaitingTime !== '00-00'
          ? true
          : undefined,
      reservation:
        RestData?.openStatus?.open &&
        RestData?.sessionSettings?.reservations &&
        RestData?.future_activities?.reservation,
      experience: RestData?.openStatus?.open,
      dine_in: RestData?.orderSessionSettings?.dine_in_order,
      take_away: RestData?.orderSessionSettings?.take_away,
      curb_side: RestData?.orderSessionSettings?.curb_side,
      skip_line: RestData?.orderSessionSettings?.skip_line,
    });
  }, []);

  const services = [
    {
      name: 'walk_in',
      title: 'Walk-In',
      link: '#',
      value:
        subscriptionData?.seating?.walkin_waitlist?.value &&
        RestData?.seatingWaitingTime === '00-00',
      status: open_status.walk_in,
    },
    {
      name: 'wait_list',
      title: 'Waitlist',
      link: '#',
      value:
        subscriptionData?.seating?.walkin_waitlist?.value &&
        RestData?.seatingWaitingTime !== '00-00',
      status: open_status.wait_list,
    },
    {
      name: 'experience',
      title: 'Experience',
      link: `${
        RestData?.openStatus?.open ? `/restaurant/${props?.id}/experience` : '#'
      }`,
      value: subscriptionData?.experience_management?.value,
      status: open_status.experience,
    },
    {
      name: 'reservation',
      title: 'Reservation',
      link: `${
        RestData?.sessionSettings?.reservations &&
        RestData?.future_activities?.reservation
          ? `/restaurant/${props?.id}/reservation`
          : '#'
      }`,
      value: subscriptionData?.seating?.reservation?.value,
    },
    {
      name: 'dine_in',
      title: 'Dine In',
      value: subscriptionData?.ordering?.dine_in_order?.value,
      status: open_status.dine_in,
    },
    {
      name: 'take_away',
      title: 'Take Away',
      value: subscriptionData?.ordering?.take_away?.value,
      status: open_status.take_away,
    },
    {
      name: 'curb_side',
      title: 'curb Side',
      value: subscriptionData?.ordering?.cubside_delivery.value,
      status: open_status.curb_side,
    },
    {
      name: 'skip_line',
      title: 'Skip the Line',
      value: RestData?.orderSessionSettings?.skip_line,
      status: open_status.skip_line,
    },
  ];

  const navigate = useNavigate();

  // eslint-disable-next-line
  const [
    activeReqCancel,
    activeReqResponseData,
    activeReqError,
    activeReqLoaded,
    activeReqReset,
    activeReqExecuteAPI,
  ] = useAxios();

  const onClickLink = async (e, u) => {
    u.preventDefault();
    if (RestData?.openStatus?.open) {
      if (
        (e?.name === 'wait_list' || e?.name === 'walk_in') &&
        sessionStorage.getItem('accessToken')
      ) {
        activeReqExecuteAPI(
          `${URL.USER_DINER}?vendor_id=${
            JSON.parse(sessionStorage.getItem('restaurantData'))?.vendorId
          }`,
          'GET',
          undefined,
          {
            Authorization: sessionStorage.getItem('accessToken'),
          }
        )
          .then((responseData) => {
            if (responseData?.data?.status && responseData?.data?.data?._id) {
              navigate(
                `/restaurant/${props?.id}/seating/track/${responseData?.data?.data?._id}`
              );
            } else {
              navigate(`/restaurant/${props?.id}/seating`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (e?.name === 'wait_list' || e?.name === 'walk_in') {
        navigate(`/restaurant/${props?.id}/seating`);
      } else if (
        e?.name === 'dine_in' ||
        e?.name === 'take_away' ||
        e?.name === 'curb_side'
      ) {
        if (e.status) {
          sessionStorage.setItem('defaultOrderType', e?.name);
          if (sessionStorage.getItem('accessToken')) {
            // if (sessionStorage.getItem('dine_in_details')) {
            //   navigate(`/restaurant/${RestData._id}/viewmenu`);
            // } else {
            //   props?.setshowDineInModal(true);
            // }
            navigate(`/restaurant/${RestData._id}/viewmenu`);
          } else {
            props.setshowOtpModal(true);
          }
        }
      } else if (e?.name === 'skip_line') {
        if (e.status) {
          sessionStorage.setItem('defaultOrderType', e?.name);
          if (sessionStorage.getItem('accessToken')) {
            if (sessionStorage.getItem('dine_in_details')) {
              navigate(`/restaurant/${RestData._id}/viewmenu`);
            } else {
              props?.setshowDineInModal(true);
            }
          } else {
            props.setshowOtpModal(true);
          }
        }
      } else if (e?.name === 'experience') {
        navigate(`/restaurant/${RestData?._id}/experience`);
      } else if (e?.name === 'reservation')
        navigate(`/restaurant/${RestData?._id}/reservation`);
    }
  };

  return (
    <>
      <div className="services py-3  hidescrollbar">
        {services.map((data) => {
          return (
            <React.Fragment key={data?.id}>
              {data?.value ? (
                <div className="services-images-inside my-4">
                  <div
                    to={data?.link || '#'}
                    onClick={(e) => {
                      onClickLink(data, e);
                    }}
                  >
                    <div className="text-center">
                      <Image
                        alt="image"
                        title="waitList"
                        roundedCircle
                        width="60px"
                        height="60px"
                        src={
                          ICONS[
                            open_status[data?.name]
                              ? 'RESTAURANT_OPEN'
                              : 'RESTAURANT_CLOSE'
                          ][data?.name]
                        }
                      />
                      <div className="f14-400 nowrap py-2">{data?.title}</div>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default Services;
