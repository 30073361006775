import React, { useState, useEffect } from "react";
import { BackIconComponent } from "./BackIconComponent";
import { Image } from "react-bootstrap";
import { RWebShare } from "react-web-share";
//import { Drawer,Button } from "@mui/material";
import CallModal from "./CallModal";

const TitleSection = (props) => {
  const [RestData, setRestaurantData] = useState({});
  const bgcolor = { backgroundColor: props?.bg };

  const [callOpen, setCallOpen] = useState(false);
  const closeCallModal = () => {
    setCallOpen(false);
  };

  useEffect(() => {
    if (sessionStorage.getItem("restaurantData")) {
      setRestaurantData(JSON.parse(sessionStorage.getItem("restaurantData")));
    }
  }, []);
  return (
    <>
      <div
        className={`title-section d-flex align-items-center py-3 ${
          props?.className || ""
        }`}
        style={bgcolor}
      >
        <div className="d-flex align-items-center">
          <BackIconComponent
            route={props?.backRoute || ""}
            backButton={props?.backButton}
          />
        </div>
        <div className="row w-100 m-0">
          <div
            className={`${
              props.noIconDisplay ? "col-12" : "col-8"
            } d-flex align-items-center`}
          >
            {props?.resticon && (
              <span>
                {" "}
                <Image
                  src={props?.resticon}
                  alt="icon"
                  width="22px"
                  height="22x"
                  roundedCircle
                  style={{ maxWidth: "100%" }}
                />
              </span>
            )}
            <div className="mx-2">
              <div className="f18-700 px-1"> {props.title}</div>
              <div
                className="f10-700 px-1 opacity-50 nowrap "
                style={{
                  color: props?.subtitlecolor ? props?.subtitlecolor : "",
                }}
              >
                {" "}
                {props.subtitle}
              </div>
            </div>
          </div>
          {props.noIconDisplay ? (
            <></>
          ) : (
            <div className="d-flex justify-content-end align-items-center col-4 px-4 mt-1">
              {props?.icon && (
                <>
                  <div className=" px-2">
                    <Image
                      src={props?.icon}
                      alt="icon"
                      width="16px"
                      height="16px"
                      style={{ maxWidth: "100%" }}
                      onClick={() => {
                        if (props?.onclicksearch()) props?.onclicksearch();
                      }}
                    />
                  </div>
                </>
              )}
              {props?.callicon && (
                <div className="px-2 mt-1" onClick={() => setCallOpen(true)}>
                  <Image
                    src={require("../../assets/images/IconsandImages/call.png")}
                    alt="icon"
                    width="28px"
                    // height="24px"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              )}
              {props?.shareIcon && (
                <div className="px-2">
                  <RWebShare
                    data={props?.shareObj || ""}
                    onClick={() => console.log("shared successfully!")}
                  >
                    <Image
                      src={props?.shareIcon}
                      alt="icon"
                      width="16px"
                      height="16px"
                      style={{ maxWidth: "100%" }}
                    />
                  </RWebShare>
                </div>
              )}

              {props?.rightText && (
                <>
                  <div
                    onClick={() => props.resetfun()}
                    className="d-flex justify-content-end col-4 px-4 mt-1 text-primary"
                  >
                    {props?.rightText || ""}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <CallModal data={RestData} open={callOpen} setCallOpen={closeCallModal} />
    </>
  );
};
export default TitleSection;
