import React from 'react';

import vegfood from '../../assets/images/IconsandImages/vegfood.png';
import nonVegfood from '../../assets/images/IconsandImages/nonvegfood.png';
import BlackVector from '../../assets/images/IconsandImages/BlackVector.png';
import Line2109 from '../../assets/images/IconsandImages/Line 2109.png';

const VegNonvegFilter = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px 20px' }}>
      
      <span style={{ fontSize: '20px', fontWeight: 'bold' }}>Places Near You</span>

      <div
        style={{
          position: 'relative',
          backgroundImage: `url(${BlackVector})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '150px', 
          height: '60px',
          transform: 'translateY(-0.001px)' 
        }}
      >
        {/* Veg Button */}
        <div style={{ marginRight: '15px' }}>
          <button
            style={{
              background: 'transparent',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transform: 'translateY(-7px)'
            }}
          >
            <img
              src={vegfood}
              alt="Veg"
              style={{
                width: '30px',
                height: '20px',
                borderRadius: '0 40% 40% 0', 
              }}
            />
          </button>
        </div>

         <img
          src={Line2109}
          alt="Divider Line"
          style={{
            width: '1.6px', 
            height: 'auto', 
            margin: '0 10px',
            transform: 'translateY(-12px)' 
          }}
        />

        <div style={{ marginLeft: '12px' }}>
          <button
            style={{
              background: 'transparent',
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transform: 'translateY(-10px)'
            }}
          >
            <img
              src={nonVegfood}
              alt="Non-Veg"
              style={{
                width: '30px',
                height: '25px',
                borderRadius: '0 40% 40% 0', 
              }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VegNonvegFilter;
