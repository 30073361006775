import MenuDialog from "./MenuDialog";
import CustomSwitch from "../ReusableComponent/Switch";
import { useState } from "react";

export const MenuFilterSection = ({restData, applyFilter, vegOnly, vegonlyfun,payloadData}) => {

  const [vegoOnly,setVegOnly]= useState(false);

  return (
    <div className="row px-4 align-items-center">
      <div className="col-6">
        <div className="d-flex align-items-center">
          <MenuDialog data={restData} applyFilter={applyFilter}  payloadData={payloadData} />
        </div>
      </div>
      <div className="col-6" align="right">
        {/* <div
          className="d-flex align-items-center justify-content-center border"
          style={{ width: "fit-content", borderRadius: "20px" }}
        >
          <div
            className="pr-2 px-3 py-2"
            style={{ borderRadius: "20px 0px 0px 20px " }}
          >
            <Image
              alt="Filter Icon"
              title="Filter Icon"
              src={require("../../assets/images/IconsandImages/non-veg-food.png")}
              width={20}
            />
          </div>
          <Image
            src={require("../../assets/images/IconsandImages/Line-gray.png")}
            height={35}
            width={1}
          />
          <div
            className="pl-2 py-2 px-3"
            style={{ borderRadius: "0px 20px 20px 0px " }}
          >
            <Image
              alt="Filter Icon"
              title="Filter Icon"
              src={require("../../assets/images/IconsandImages/veg-food.png")}
              width={20}
            />
          </div>
        </div> */}
        <div className="switch d-flex justify-content-end" >
          <div className="f14-500">Veg Only</div>
          <CustomSwitch 
            value={vegOnly}
            onChange={() => vegonlyfun(!vegOnly)}
          />
          
        </div>
      </div>
    </div>
  );
};
