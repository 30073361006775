import React from 'react';

import Rectangle39721 from '../../assets/images/IconsandImages/Rectangle 39721.png'; 
import filterarrow from '../../assets/images/IconsandImages/filterarrow.png'; 

const HomePageFilterBttn = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '600px',
        margin: '20px auto',
        marginRight:'2px',
        marginTop:'-30px'
      }}
    >
      <button 
        style={{
          display: 'flex',              
          alignItems: 'center',         
          backgroundImage: `url(${Rectangle39721})`, 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '20px',         
          width: '120px',                
          height: '35px',                
          color: '#333',                 
          cursor: 'pointer',
          padding: '0 15px',            
          justifyContent: 'space-between', 
        }}
      >
        <span>Sort by</span>
        <img 
          src={filterarrow} 
          alt="Arrow" 
          style={{ width: '9px', height: '8px' }} 
        />
      </button>

      <button 
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `url(${Rectangle39721})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '20px',
          width: '120px',
          height: '35px',
          color: '#333',
          cursor: 'pointer',
          padding: '0 15px',
          justifyContent: 'space-between',
        }}
      >
        <span>Services</span>
        <img 
          src={filterarrow} 
          alt="Arrow" 
          style={{ width: '9px', height: '8px' }}
        />
      </button>

      <button 
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `url(${Rectangle39721})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '20px',
          width: '120px',
          height: '35px',
          color: '#333',
          cursor: 'pointer',
          padding: '0 15px',
          justifyContent: 'space-between',
        }}
      >
        <span>Cuisines</span>
        <img 
          src={filterarrow}
          alt="Arrow" 
          style={{ width: '9px', height: '8px' }}
        />
      </button>

      <button 
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `url(${Rectangle39721})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '20px',
          width: '130px',                
          height: '35px',
          color: '#333',
          cursor: 'pointer',
          padding: '0 15px',
          justifyContent: 'space-between',
        }}
      >
        <span>Cost for two</span>
        <img 
          src={filterarrow} 
          alt="Arrow" 
          style={{ width: '9px', height: '8px' }}
        />
      </button>
    </div>
  );
};

export default HomePageFilterBttn;
