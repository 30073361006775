export const screenSetup = {
  CURATED: {
    PASS: {
      BACKGROUND: require("../../assets/images/ExperiencePageAssets/pass_card.png"),
    },
    PACKAGE: {
      BACKGROUND: require("../../assets/images/ExperiencePageAssets/package_card.png"),
    },
    AVAILABLE_BOOKED : true,
  },
  SPECIAL: {
    PASS: {
      BACKGROUND: require("../../assets/images/ExperiencePageAssets/package_card.png"),
    },
    PACKAGE: {
      BACKGROUND: require("../../assets/images/ExperiencePageAssets/package_card.png"),
    },
    AVAILABLE_BOOKED : false,
  },
};
