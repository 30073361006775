import React, { useState, useEffect } from 'react';
import { Image, Modal, Button } from 'react-bootstrap';
import { URL } from '../../common/api/urls';
// import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { useAxios } from '../../common/api/hooks/useAxios';
import { AnalyticPage } from '../../common/enums/GlobalEnums';

const DownloadBar = ({ title, text, buttonText, noImage }) => {
  const { ID } = useParams();
  const [open, setOpen] = useState(true);
  const [display, setdisplay] = useState('');
  const restDetails = sessionStorage.getItem('restaurantData');
  const [count, setcount] = useState(0);
  // eslint-disable-next-line
  const [
    pageStatscancel,
    pageStatsresponseData,
    pageStatserror,
    pageStatsloaded,
    pageStatsreset,
    pageStatsexecuteAPI,
  ] = useAxios();

  useEffect(() => {
    if (count > 0) {
      if ((ID, restDetails && sessionStorage.getItem('BroserId'))) {
        const params = {
          deviceType: '',
          page: AnalyticPage.APP_DOWNLOAD,
          deviceId: sessionStorage.getItem('BroserId'),
          app: 'WEB_APP',
        };
        pageStatsexecuteAPI(
          `${URL.PAGESTATS}?restaurantId=${ID}`,
          'POST',
          params
        );
      }
    }
  }, [count]);

  return (
    <>
      <div
        className="bg-white d-flex justify-content-between align-items-center "
        style={{ visibility: display }}
      >
        <div className="d-flex align-items-center">
          {noImage ? null : (
            <div className="p-2">
              <Image
                alt="image"
                title="image"
                width="30px"
                src={require('../../assets/images/IconsandImages/amealiologo.png')}
              />
            </div>
          )}
          <div className={` ${text ? 'mx-3' : 'mx-2'}  py-2`}>
            <div className="f12-700">{title ? title : 'Amealio'}</div>
            <div className={text ? 'f10-500' : 'f8-500'}>
              {text ? text : 'Download the app for best experience'}
            </div>
          </div>
        </div>
        <div className="mx-2 d-flex">
          <a href={URL.USER_APP_DOWNLOAD_LINK} targer="_blank">
            <button
              className="primary-bg-color px-3 py-1 my-1 text-center w-100 text-white f10-500"
              style={{ borderRadius: '12px', border: 'none' }}
              onClick={() => setcount(count + 1)}
            >
              {buttonText ? buttonText : 'Download'}
            </button>
          </a>
          <span
            className="text-center opacity-25 px-2"
            onClick={() => setdisplay('hidden')}
          >
            {/* <CloseIcon fontSize="small" /> */}
          </span>
        </div>
      </div>
      {/* <Modal
        size="sm"
        show={open}
        onHide={() => setOpen(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            <div className="bg-white d-flex justify-content-between align-items-center ">
              <div className="d-flex align-items-center">
                <div className="p-1">
                  <Image
                    alt="image"
                    title="image"
                    width="20px"
                    src={require("../../assets/images/IconsandImages/amealiologo.png")}
                  />
                </div>
                <div className="mx-2 py-2">
                  <div className="f14-700">Amealio</div>
                  <div className="f8-500 nowrap" style={{ color: "grey" }}>
                    Download the app for best experience
                  </div>
                </div>
              </div>
              <div className="px-2">
                <button
                  className="primary-bg-color px-3 py-1 my-1 text-center w-100 text-white f10-500"
                  style={{ borderRadius: "12px", border: "none" }}
                >
                  Download
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        
      </Modal> */}
    </>
  );
};
export default DownloadBar;

export const ViewMenuBar = () => {
  const navigate = useNavigate();
  const { restaurantId } = useParams();

  return (
    <>
      <div className="mt-2 pb-1 px-2 viewmenucard">
        <Image
          src={require('../../assets/images/IconsandImages/greydesignbar.png')}
          height="12px"
          width="100%"
          className="px-1"
          style={{ marginTop: '-16px', maxWidth: '100%' }}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div className="p-1">
            <div className="f14-500 primary-color">Pre-Order Food Now!</div>
            <div className="f12-500">Download our app to pre order food</div>
          </div>
          <div>
            <Button
              variant="outline-danger"
              onClick={() => navigate(`/restaurant/${restaurantId}/viewmenu`)}
              className="btn-sm"
            >
              View Menu
            </Button>{' '}
          </div>
        </div>
      </div>
    </>
  );
};
