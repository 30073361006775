import { FLAT_CHARGE_KEYS } from '../../../common/enums/OrderEnums';

export function getFlatCharges(subscription, type) {
  let taxes = [];
  let percentage = 0;
  let value = 0;
  let keys = [];

  if (subscription?.casual_dining) {
    let taxesData =
      subscription?.casual_dining_status?.ordering?.order_general?.tax_values ||
      [];
    taxes = taxesData.filter((ele) => ele.flatCharges);
  } else if (subscription?.fast_food_dining) {
    let taxesData =
      subscription?.fast_food_dining_status?.ordering?.order_general
        ?.tax_values || [];
    taxes = taxesData.filter((ele) => ele.flatCharges);
  } else if (subscription?.multi_service_business) {
    let taxesData =
      subscription?.multi_service_business_status?.ordering?.order_general
        ?.tax_values || [];
    taxes = taxesData.filter((ele) => ele.flatCharges);
  }

  taxes.forEach((ele) => {
    if (ele[FLAT_CHARGE_KEYS[type]]) {
      keys.push(ele.code || ele.name?.split('-')[0].trim());
    }
    if (ele.tax_value === 'VALUE') {
      value += ele[FLAT_CHARGE_KEYS[type]];
    } else {
      percentage += ele[FLAT_CHARGE_KEYS[type]];
    }
  });

  return { percentage, value, taxes, keys };
}
