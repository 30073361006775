import React from 'react';
import { Image } from 'react-bootstrap';
import { NOTAVAILABLEIMAGE } from '../../../common/enums/GlobalEnums';
import {
  CartPageAddButton,
  cartPageAddButton,
} from '../../ReusableComponent/Buttons';

function CartMenuCard({
  index,
  item,
  storedItems,
  setStoredItems,
  fullItemData,
}) {
  const incrementHander = () => {
    const updatedItem = {
      ...fullItemData,
      quantity: fullItemData.quantity + 1,
    };
    const updatedItems = [...storedItems];
    updatedItems[index] = updatedItem;
    sessionStorage.setItem('cartItems', JSON.stringify(updatedItems));
    setStoredItems(updatedItems);
  };

  const decrementHandler = () => {
    if (fullItemData.quantity === 1) {
      // If quantity is 1, remove the item from the array
      const updatedItems = storedItems.filter((item, i) => i !== index);

      sessionStorage.setItem('cartItems', JSON.stringify(updatedItems));
      setStoredItems(updatedItems);
    } else if (fullItemData.quantity > 1) {
      // If quantity is greater than 1, decrement the quantity
      const updatedItem = {
        ...fullItemData,
        quantity: fullItemData.quantity - 1,
      };

      const updatedItems = [...storedItems];
      updatedItems[index] = updatedItem;

      sessionStorage.setItem('cartItems', JSON.stringify(updatedItems));
      setStoredItems(updatedItems);
    }
  };

  return (
    <div>
      <div className="menu-card">
        <div className="d-flex justify-content-between p-2">
          <div className="d-flex align-items-center justify-content-evenly">
            <Image
              src={require(`../../../assets/images/IconsandImages/${
                item?.veg ? 'vegindicator.png' : 'nonveg_indicator.png'
              }`)}
              alt="Image"
              width="14px"
              height="14px"
            />
            {item?.primary_tag && item?.primary_tag.length ? (
              <div className="mx-2">
                <span
                  className="py-0 px-2  f10-500"
                  style={{
                    border: '0.5px solid #4CAF50',
                    borderRadius: '10px',
                    color: '#4CAF50',
                    backgroundColor: '#4CAF5026',
                  }}
                >
                  {item?.primary_tag[0].title}
                </span>
              </div>
            ) : null}
          </div>
          {/* <div>
            <Image
              src={require("../../assets/images/IconsandImages/heart.png")}
              alt="Image"
              width="20px"  
              className="mx-1"
            />
            <Image
              src={require("../../assets/images/IconsandImages/videocam.png")}
              alt="Image"
              width="20px" 
              className="mx-1" 
              
            />
          </div> */}
        </div>
        <div className="row m-0">
          <div className="col-4 p-2 flexCenter">
            <Image
              src={item?.images?.[0] || NOTAVAILABLEIMAGE}
              alt="Image"
              width="120px"
              height="82px"
              style={{
                borderRadius: '12px',
                maxHeight: '100px',
                maxWidth: '100%',
                objectFit: 'cover',
              }}
              className="text-center"
            />
          </div>
          <div className="col-8 d-flex align-items-center px-2">
            <div>
              <div className="f16-700 my-2">{item?.name}</div>
              <div className="d-flex my-2">
                <div className="d-flex ">
                  {item?.rating ? (
                    <div className="pr-1">
                      <span>
                        <Image
                          src={require('../../../assets/images/IconsandImages/warningstar.png')}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                      <span className="f14-500 opacity- mx-1">
                        {item?.rating || 0}
                      </span>
                    </div>
                  ) : null}
                  <div className="px-1 ">
                    <span>
                      <Image
                        src={require('../../../assets/images/IconsandImages/timer.png')}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500 opacity-50 mx-1">
                      {item?.prepTime || 0} min
                    </span>
                  </div>
                  {item?.spice_level?.value && (
                    <div className="px-1 ">
                      <span className="f14-500 opacity-50 mx-1">
                        {item?.spice_level?.level}
                      </span>
                      <span>
                        <Image
                          src={require('../../../assets/images/IconsandImages/chili.png')}
                          alt="Image"
                          width="12px"
                          fluid
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
                            <div className="d-flex align-items-center justify-content-end my-2">
                {/* Button to Add Note */}
                <button
                  className="btn d-flex align-items-center"
                  style={{
                    border: 'none',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                  }}
                  onClick={() => console.log('Add Note button clicked!')}
                >
                  <span className="f14-400" style={{ marginRight: '8px' }}>Item note</span>
                  <Image
                    src={require('../../../assets/images/IconsandImages/pencilGrey.png')}
                    alt="Edit Note"
                    width="16px"
                    height="16px"
                  />
                </button>
              </div>
              <div className="f14-700 my-2 d-flex align-items-center justify-content-between">
                <div>₹ {fullItemData?.finalPrice}</div>
                <div>
                  {' '}
                  {/* <AddButton
                    item={item}
                    storedItems={storedItems}
                    setStoredItems={setStoredItems}
                    orderSelected={orderSelected}
                  /> */}
                  <CartPageAddButton
                    item={fullItemData}
                    incrementHander={incrementHander}
                    decrementHandler={decrementHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartMenuCard;
