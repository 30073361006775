import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import useWindowWidth from '../../common/hooks/useWindowWidth';

const HomeImageSlider = () => {
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 1024;

  // Images loaded using require
  const images = [
    require('../../assets/images/IconsandImages/Frame 1000006960.png'),
    require('../../assets/images/IconsandImages/Frame 1000006961.png'),
    require('../../assets/images/IconsandImages/Frame 1000006962.png'),
  ];

  return (
    <div
      className="image-slider"
      style={{
        width: '100%',
        maxWidth: isDesktop ? '100%' : '400px', // Full width in desktop, fixed width in mobile
        margin: '0 auto',
        padding: '20px 0',
      }}
    >
      {/* Mobile view: display images one by one in a carousel */}
      {!isDesktop ? (
        <Carousel indicators={true} controls={false}>  {/* Enabling indicators and disabling controls */}
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={image}
                alt={`Slide ${index + 1}`}
                style={{
                  borderRadius: '20px',
                  height: '200px', // Maintain fixed height
                }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        // Desktop view: show images side by side and manually styled indicators (dots)
        <div className="desktop-slider" style={{ display: 'flex', justifyContent: 'space-between' }}>
          {images.map((image, index) => (
            <div key={index} style={{ flex: '1 1 30%', textAlign: 'center' }}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{
                  width: '100%',
                  height: '200px',
                  borderRadius: '20px',
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HomeImageSlider;
