import styled from '@emotion/styled';
import {
  Box,
  Button,
  Drawer,
  Rating,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { assets } from '../../assets/assets';
import { useAxios } from '../../common/api/hooks/useAxios';
import { URL } from '../../common/api/urls';

function RatingsAndReviewDrawer({
  open,
  setOpen,
  expData,
  UserData,
  setCount,
  count,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? '100%' : 500;
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);

  // eslint-disable-next-line
  const [cancel, responseData, error, loaded, reset, executeAPI] = useAxios();

  const handleSubmit = async () => {
    const formData = {
      experienceId: expData?._id,
      expRequestId: '',
      review: review,
      rating: rating,
      user_id: UserData?._id,
      isUser: true,
      vendor_id: expData?.vendorId,
      restaurant_id: expData?.restaurantId?._id,
      type: 6,
    };

    try {
      // Await the API call directly
      await executeAPI(
        `${URL.RATINGS_REVIEWS}?isUser=true`,
        'POST',
        {
          experienceId: expData?._id,
          expRequestId: '',
          review: review,
          rating: rating,
          user_id: UserData?._id,
          isUser: true,
          vendor_id: expData?.vendorId,
          restaurant_id: expData?.restaurantId?._id,
          type: 6,
        },
        {
          Authorization: sessionStorage.getItem('accessToken'),
        }
      );
      setCount(++count);
      setReview('');
      setRating(0);
      setOpen(false);
    } catch (error) {
      // Handle any errors that may occur during the API call
      console.error('Error occurred while executing API:', error);
    }
  };

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: assets.colors.green,
    },
    '& .MuiRating-iconHover': {
      color: assets.colors.green,
    },
  });

  const handleCancel = () => {
    setReview('');
    setRating(0);
    setOpen(false);
  };

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={open}
        // onClose={cancelTrigger}
        PaperProps={{
          style: {
            width: drawerWidth,
            margin: 'auto',
            // height: '100vh',
            borderRadius: '16px',
          },
        }}
      >
        <Box p={3}>
          <div className="f16-700 my-2">Rate Your Event experience with us</div>
          <StyledRating
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            size="large"
            my={2}
            color="green"
          />
          <Box className="restaurant_title_card my-2">
            <div className="row">
              <div className="col-4 flexCenter">
                <Image
                  src={expData?.restaurantId?.logo_url_thumbnails}
                  width={78}
                  style={{ borderRadius: '50%' }}
                />
              </div>
              <div className="col-8">
                <div className="f14-700 my-2">{expData?.restaurantName}</div>
                <div className="f12-700 my-1">
                  <span className="text-grey">Experience Id :</span>{' '}
                  {expData?.expId}
                </div>
                <div className="f12-700 my-1">
                  <span className="text-grey">Type :</span> {expData?.expType}
                </div>
                <div className="f12-700 mb-2">
                  <span className="text-grey">Date:</span>{' '}
                  {moment(expData?.startDate).format('MMM DD yyyy')}
                </div>
              </div>
            </div>
          </Box>
        </Box>
        <Box px={3} py={1}>
          <div className="row">
            <div className="col-4 flexCenter">
              <Image src={expData?.photos[0]} width={78} rounded />
            </div>
            <div className="col-6 f16-700">{expData?.name}</div>
            <div className="p-3 f12-500 text-grey">
              {expData?.description.slice(0, 120)}
            </div>
          </div>
          <div className="row"></div>
        </Box>
        <Box p={3}>
          <div className="f16-700 my-3">Write a Review</div>
          <div className="my-2">
            <TextField
              id="outlined-basic"
              label="write Here"
              variant="outlined"
              // size="small"
              fullWidth
              className="customTexfeild"
              minRows={4}
              value={review}
              onChange={(e) => setReview(e.target.value)}
              // error={paxNumber > maxPaxValue}
              //   helperText={
              //     paxNumber > maxPaxValue
              //       ? `Maximum party size is ${maxPaxValue}  PAX.`
              //       : ''
              //   }
            />
          </div>
        </Box>
        <hr />
        <Box
          sx={{ display: 'flex', justifyContent: 'space-evenly' }}
          my={1}
          px={2}
        >
          <Button
            variant="outlined"
            size="large"
            onClick={() => handleCancel()}
            style={{
              borderRadius: '15px',
              color: 'black',
              border: '1px solid #C4C4C4',
              textTransform: 'capitalize',
            }}
            className="bg-white mr-2"
            fullWidth
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            size="large"
            style={{
              borderRadius: '15px',
              border: '1px solid #C4C4C4',
              color: 'white',
              backgroundColor: '#fc5a47',
              textTransform: 'capitalize',
            }}
            fullWidth
            disabled={!review}
            onClick={() => handleSubmit()}
          >
            Continue
          </Button>
        </Box>
      </Drawer>
    </div>
  );
}

export default RatingsAndReviewDrawer;
