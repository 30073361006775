import moment from 'moment';

export const HorizontalScrollMenu = ({ data, onClick, selected }) => {
  const onClicked = (e) => {
    if (e === selected) {
      if (onClick) onClick('');
    } else {
      if (onClick) onClick(e);
    }
  };
  return (
    <div className="d-flex overflow-scroll hidescrollbar">
      {data?.map((e) => (
        <div className="p-1" key={e?.title}>
          <div
            onClick={() => (e?._id ? onClicked(e?._id) : onClicked(e))}
            className={`py-1 px-4 f14-400 badge-item text-center ${
              e?._id
                ? selected === e._id
                  ? 'selected-indigo'
                  : null
                : selected === e
                ? 'selected-indigo'
                : null
            }`}
            style={{
              backgroundColor: e?.code || 'none',
              color: e?.code ? 'black' : 'none',
            }}
          >
            {e?.title || e?.toString() || ''}
          </div>
        </div>
      ))}
    </div>
  );
};

export const HorizontalScrollMenuTimings = ({ data, onClick, selected }) => {
  const onClicked = (e) => {
    if (e === selected) {
      if (onClick) onClick('');
    } else {
      if (onClick) onClick(e);
    }
  };
  return (
    <div className="d-flex overflow-scroll hidescrollbar">
      {data?.map((e) => (
        <div className="p-1" key={e}>
          <div
            onClick={() => onClicked(e)}
            className={`py-3 px-4 f14-400 badge-item bg-white text-center py-1 ${
              selected === e
                ? 'badge-item-selected-border-focus text-black'
                : null
            }`}
          >
            {moment(e, 'hh:mm a').format('hh:mm A') || ''}
          </div>
        </div>
      ))}
    </div>
  );
};
