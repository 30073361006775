import * as React from "react";
import Button from "@mui/material/Button";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useEffect } from "react";

function MyApp({ alert, variant }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (msg) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  useEffect(() => {
    if (alert) handleClickVariant(alert);
  }, [alert]);

  return (
    <React.Fragment>
      {/* <Button onClick={handleClickVariant("success")}>
        Show success snackbar
      </Button> */}
    </React.Fragment>
  );
}

export default function NotificationAlert({ alert, variant }) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MyApp alert={alert} variant={variant} />
    </SnackbarProvider>
  );
}
