import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import { Image } from "react-bootstrap";

export const VenueCard = ({ data, restaurantData }) => {
  return (
    <div style={{ backgroundColor: "#FFFBFA" }}>
      <Image
        src={require("../../assets/images/ExperiencePageAssets/design_2.png")}
        width={"100%"}
      />
      <div className="row m-0">
        <div className="col-1 px-1 py-3">
          <LocationOnOutlinedIcon />
        </div>
        <div className="col-11 p-3">
          <div className="f14-500">Venue / Address</div>
          <div className="f14-400 mt-2">{data?.venueAddress}</div>
        </div>
      </div>
      <div className="row mx-4 py-2">
        <div className="col-5" style={{ borderRight: "1px solid #A28AE4" }}>
          <a
            href={`tel:${restaurantData?.contact_detail?.[0]?.mobile_no}`}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="d-flex align-items-center f12-500 py-1 justify-content-center"
              style={{
                border: "1px solid #A28AE4",
                borderRadius: "11px",
              }}
            >
              <CallOutlinedIcon />
              <div className="ml-1">Call Now</div>
            </div>
          </a>
        </div>
        <div className="col-5">
          <a
            href={`http://www.google.com/maps/place/${data?.venue?.latitude},${data?.venue?.longitude}`}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="d-flex align-items-center f12-500 py-1 justify-content-center"
              style={{
                border: "1px solid #A28AE4",
                borderRadius: "11px",
              }}
            >
              <NearMeOutlinedIcon />
              <div className="ml-1">Directions</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
