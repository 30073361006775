import { useState, useCallback } from 'react';
import { get, post, patch } from './apiService'; // Import API methods from the service
import { useNotification } from '../../../components/alerts/NotificationContext';
// Use the notification context

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification(); // Use notification context for global alerts

  const handleRequest = useCallback(
    async (
      method, // API method: 'get', 'post', 'patch'
      url, // API endpoint
      data = null, // Payload for POST/PATCH
      options = {} // Custom options for notifications, callbacks, and messages
    ) => {
      const {
        onSuccess, // Success callback
        onError, // Error callback
        notify = true, // Enable/disable notifications
        successMessage = 'Request successful!', // Custom success message
        errorMessage = 'An error occurred.', // Custom error message
      } = options;

      setLoading(true);
      try {
        let response;
        if (method === 'get') {
          response = await get(url);
        } else if (method === 'post') {
          response = await post(url, data);
        } else if (method === 'patch') {
          response = await patch(url, data);
        }

        // Show success notification if enabled
        if (notify) showNotification(successMessage, 'success');

        // Call success callback if provided
        if (onSuccess) onSuccess(response);

        return response; // Return response for further processing
      } catch (error) {
        // Show error notification if enabled
        if (notify) showNotification(errorMessage || error.message, 'error');

        // Call error callback if provided
        if (onError) onError(error);

        throw error; // Rethrow for further error handling
      } finally {
        setLoading(false);
      }
    },
    [showNotification]
  );

  return { handleRequest, loading };
};

export default useApi;
