import React, { useState } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";

const HomePageSearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Form className="position-relative" style={{ marginTop: "12px", maxWidth: "480px",marginLeft:"7px" }}>
      <InputGroup className="rounded-pill" style={{ boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)" }}>
        
        <InputGroup.Text className="bg-white border-0 ps-3" style={{ borderRadius: "50px 0 0 50px" }}>
          <img
            src={require("../../assets/images/IconsandImages/search.png")}
            alt="Search Icon"
            style={{ width: "20px", height: "20px" }}
          />
        </InputGroup.Text>

        <FormControl
          type="text"
          placeholder="Search for restaurants/ cuisines or dishes"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            borderRadius: "50px",
            paddingLeft: "10px",
            border: "none",
            boxShadow: "none",
          }}
        />

        <InputGroup.Text className="bg-white border-0 pe-3" style={{ borderRadius: "0 50px 50px 0" }}>
          <img
            src={require("../../assets/images/IconsandImages/mic.png")}
            alt="Microphone Icon"
            style={{ width: "20px", height: "20px" }}
          />
        </InputGroup.Text>
      </InputGroup>

      <span
        style={{
          position: "absolute",
          right: "-30px",  
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <img
          src={require("../../assets/images/IconsandImages/qrcode.png")}
          alt="QR Code Icon"
          style={{ width: "20px", height: "20px" }}
        />
      </span>
    </Form>
  );
};

export default HomePageSearchBar;
