import React, { useState, useEffect } from 'react';
import { Col, Row, Image } from 'react-bootstrap';

const RestaurantCard = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024); // Use 1024px as the breakpoint

  // Detect screen size change
  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 1024); // Update when window width changes
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia); // Cleanup
  }, []);

  return (
    <div
      className="restaurant_title_card"
      style={{
        position: 'relative',
        borderRadius: '25px',
        border: '1px solid lightgray',
        backgroundColor: 'white',
        marginTop: '20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '90%', // Keep it consistent for both mobile and desktop
        maxWidth: '400px', // Set a maximum width for larger screens
        margin: 'auto', // Center the card
        marginLeft:'7px',
        marginBottom:'20px'
      }}
    >
      <div className="justify-content-between align-items-center d-flex first_layer">
        <span
          className="py-1 px-4 f2-500"
          style={{
            backgroundColor: '#F86B00',
            color: 'white',
            borderRadius: '15px',
            fontSize: '12px',
            marginTop: '-2px',
          }}
        >
          PREMIUM
        </span>
      </div>

      <Row className="p-1 pb-3 m-0">
        <Col xs={4} className="d-flex align-items-center">
          <Image
            src={require('../../assets/images/IconsandImages/HH.png')}
            alt="restaurantImage"
            className="restaurant_card_image"
            style={{ borderRadius: '50%', width: '90px', marginTop: '0px' }}
          />
          <div className="pl-3">
            <div
              className="f10-700"
              style={{ fontWeight: 'bold', fontSize: '18px', whiteSpace: 'nowrap', marginTop: '-45px' }}
            >
              Huber & Holly
            </div>
            <div
              className="f12-400 restaurant_cusine_color"
              style={{ fontSize: '12px', marginTop: '2px' }}
            >
              Italian, Chinese, +2
            </div>
          </div>
        </Col>
      </Row>

      <Row className="pb-1 mt-0.9 justify-content-center">
        <Col xs={4} className="d-flex justify-content-center">
          <div className="f14-500 d-flex align-items-center">
            <Image
              src={require('../../assets/images/landingPageAssets/dessert.png')}
              alt="Image"
              className="star_rating"
              style={{ width: '18px', height: '18px', marginTop: '-30px', marginLeft: '-50px' }}
            />
            <div className="f10-600 star_color align-items-center pl-1" style={{ marginTop: '-30px', marginLeft: '10px',fontSize:'13px' }}>
              Dessert Parlour
            </div>
          </div>
        </Col>
        <Col xs={4} className="d-flex justify-content-center">
          <div className="f14-500 star_color d-flex align-items-center">
            <Image
              src={require('../../assets/images/IconsandImages/Group 87878.png')}
              alt="vegIndicator"
              className="veg_indicator"
              style={{ width: '18px', height: '18px', marginTop: '-30px',marginLeft:'-10px' }}
            />
            <div className="f14-600 star_color align-items-center pl-1" style={{ marginTop: '-30px', fontWeight: 'normal', marginLeft: '10px', whiteSpace: 'nowrap' }}>
            Pure Vegetarians
          </div>

          </div>
        </Col>
      </Row>

      <Row className="pb-1 mt-2 justify-content-center">
        <Col xs={4} className="d-flex justify-content-center">
          <div className="f14-500 d-flex align-items-center">
            <Image
              src={require('../../assets/images/IconsandImages/rupee.png')}
              alt="Cost for two"
              className="rupee_png"
              style={{ width: '16px', marginTop: '-12px',marginLeft:'-70px' }}
            />
            <div className="f14-700 star_color align-items-center pl-1" style={{ marginTop: '-12px', fontWeight: 'normal' }}>
              Cost for two
            </div>
          </div>
        </Col>
        <Col xs={4} className="d-flex justify-content-center">
          <div className="f14-500 d-flex align-items-center">
            <Image
              src={require('../../assets/images/IconsandImages/charging.png')}
              alt="Image"
              className="charging_icon"
              style={{ width: '16px', marginTop: '-12px' ,marginLeft:'-10px'}}
            />
            <div className="f14-600 star_color align-items-center pl-1" style={{ marginTop: '-12px' ,marginLeft:'10px',whiteSpace: 'nowrap'}}>
              Charging Point
            </div>
          </div>
        </Col>
      </Row>

      <Image
        src={require('../../assets/images/IconsandImages/Line 2256.png')}
        alt="divider"
        style={{ width: '100%', marginTop: '1px',marginBottom:'5px' }}
      />

      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Image
            src={require('../../assets/images/IconsandImages/direction.png')}
            alt="direction"
            className="direction_icon"
            style={{ width: '25px', height: '20px', paddingLeft: '8px' }}
          />
          <span style={{ paddingLeft: '15px' }}>3.2mi</span>
        </div>
        <div className="d-flex align-items-center">
          <Image
            src={require('../../assets/images/IconsandImages/clock1.png')}
            alt="clockIcon"
            className="clock_icon"
            style={{ width: '16px' }}
          />
          <span style={{ paddingLeft: '4px' }}>Closes at 11:00PM</span>
        </div>
        <div className="d-flex align-items-center">
          <Image
            src={require('../../assets/images/IconsandImages/star.png')}
            alt="starRating"
            className="star_rating"
            style={{ width: '16px' }}
          />
          <span style={{ paddingRight: '25px' }}>4.2</span>
        </div>
      </div>

      <div
        className="live_icon_position"
        style={{
          position: 'absolute',
          top: '10px',
          right: '50px',
        }}
      >
        <Image
          src={require('../../assets/images/IconsandImages/live1.png')}
          alt="liveIcon"
          className="live_icon"
          style={{ width: '30px' }}
        />
      </div>
      <div
        className="verification"
        style={{
          position: 'absolute',
          top: '10px',
          right: '15px',
        }}
      >
        <Image
          src={require('../../assets/images/IconsandImages/surface1.png')}
          alt="verification"
          className="verifiction"
          style={{ width: '15px' }}
        />
      </div>
    </div>
  );
};

export default RestaurantCard;

