import React from "react";
import moment from "moment";

const   WaitListDetails = ({data}) => {
    return(
       <div>
         <div className="waitlist-card">
            <div className="f16-700 mt-2 mb-3"> {data?.service_type === "RESERVATION" ? "Reservation" : data?.isWalkIn ? "Walk-in" : "WaitList"} Details</div>
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex">
                <div className="f14-500 opacity-50">Preferences:</div>
                <div className="f14-500 ">{data?.seating_preference?.[0]  || ""}</div>
              </div>
              <div>
                <div className="f14-700">
                  {" "}
                 {data?.adult_count || 0}<span className="f12-700"> Adults</span>  {data?.kids_count || 0}{" "}
                  <span className="f12-700"> kids</span>
                </div>
              </div>
            </div>
            <div className="d-flex my-2">
              <div className="f14-500 opacity-50">Special Occasion: </div>
              <div className="f14-500 ">{data?.occasion || ""}</div>
            </div>
          </div>
          <div className="waitlist-card my-3">
            <div className="f16-700 my-3">Request details</div>
            <div className="row mx-0">
              <div className="col">
                <div className="my-2">
                  <div className="f16-500">{data?.diner_id || ""}</div>
                  <div className="f14-500 opacity-50">Request Id</div>
                </div>
                <div className="my-2">
                  <div className="f16-500">{moment(data?.createdAt).format("DD MMM YYYY")}</div>
                  <div className="f14-500 opacity-50">Created Date</div>
                </div>
                <div className="my-2">
                  <div className="f16-500">{moment(data?.updatedAt).format("DD MMM YYYY")}</div>
                  <div className="f14-500 opacity-50">Last change date</div>
                </div>
              </div>
              <div className="col">
                <div className="my-2">
                  <div className="f16-500 nowrap"> {data?.diner_status === "NOTSEATED" ? "Seating Awaited" :  data?.diner_status === "SEATED" ? "Seated" : data?.diner_status}</div>
                  <div className="f14-500 opacity-50 ">Status</div>
                </div>
                <div className="my-2">
                  <div className="f16-500">{moment(data?.updatedAt).format("hh:mm A")}</div>
                  <div className="f14-500 opacity-50">Created Time</div>
                </div>
                <div className="my-2">
                  <div className="f16-500">{moment(data?.updatedAt).format("hh:mm A")}</div>
                  <div className="f14-500 opacity-50">Last Change Time</div>
                </div>
              </div>
            </div>
          </div>
       </div>
    )
};

export default WaitListDetails;