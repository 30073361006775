import React, { useRef, useState, useEffect } from "react";
import { Image } from "react-bootstrap";

export default function VideoCard({ url, item, isPlaying, onVideoClick, id,imageurl }) {
  const vidRef = useRef();
  const [showThumbnail, setShowThumbnail] = useState(true);

  useEffect(() => {
    if (isPlaying) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
  }, [isPlaying]);


  useEffect(() => {
    const video = document.querySelector(".video-player" + id);

    // Create an Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If the video is in the viewport
          if (entry.isIntersecting) {
            // Play the video
            video.play();
          } else {
            // Pause the video when it's not in the viewport (optional)
            video.pause();
          }
        });
      },
      {
        threshold: 0.5, // When at least 50% of the video is in the viewport
      }
    );

    // Start observing the video element
    observer.observe(video);
  }, []);

  useEffect(() => {
    const video = vidRef.current;
  
    const handleLoadedData = () => {
      setShowThumbnail(false);
    };
  
    // Add an event listener to hide the thumbnail when the video is loaded
    video.addEventListener("loadeddata", handleLoadedData);
  
    return () => {
      // Remove the event listener to avoid memory leaks
      video.removeEventListener("loadeddata", handleLoadedData);
    };
  }, []);


  return (
    <div className="video-cards my-2">
      {/* {showThumbnail && item.images.length && item.images[0] !== "" &&  (
        <img
          src={imageurl}
          alt="Thumbnail"
          className="video-player"
        />
      )} */}
      <video
        onClick={onVideoClick}
        className={"video-player video-player" + id}
        ref={vidRef}
        src={url}
        loop
        muted={false}
        playsInline  
        poster={imageurl} 
        webkit-playsinline="true"  // iOS-specific attribute
       x-webkit-airplay="deny"
      />
      <div>
        <div>
          <div className="p-3 video-text">
            <div className="d-flex align-items-center">
              <Image
                src={require(`../../assets/images/IconsandImages/${
                  item?.veg ? "vegindicator.png" : "nonveg_indicator.png"
                }`)}
                alt="Image"
                width="14px"
                height="14px"
              />
              <div className="px-1 mx-2">
                <span>
                  <Image
                    src={require("../../assets/images/IconsandImages/timer.png")}
                    alt="Image"
                    width="12px"
                    fluid
                  />
                </span>
                <span className="f14-500  mx-1">
                  {item?.prepTime || 0} min
                </span>
              </div>
            </div>

            <div className="f16-700 my-2">{item?.name}</div>
            <div className="f14-700 my-2">
              ₹ {item?.size.length ? item?.size[0].price : "0"}
            </div>
            <div className="d-flex my-2">
              <div className="d-flex ">
                <div className="pr-1">
                  <span>
                    <Image
                      src={require("../../assets/images/IconsandImages/warningstar.png")}
                      alt="Image"
                      width="12px"
                      fluid
                    />
                  </span>
                  <span className="f14-500 opacity- mx-1">
                    {item?.rating || 0}
                  </span>
                </div>

                <div className="px-1 ">
                  <span>
                    <Image
                      src={require("../../assets/images/IconsandImages/servesprimary.png")}
                      alt="Image"
                      width="16px"
                      fluid
                    />
                  </span>
                  <span className="f14-500  mx-1">
                    Serves {item?.size.length ? item?.size[0].pax : "0"}
                  </span>
                </div>
                {item?.spice_level?.value && (
                  <div className="px-1 ">
                    <span>
                      <Image
                        src={require("../../assets/images/IconsandImages/chili.png")}
                        alt="Image"
                        width="12px"
                        fluid
                      />
                    </span>
                    <span className="f14-500  mx-1">
                      {item?.spice_level?.level}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
