import { Button, Image, Table } from 'react-bootstrap';
import { isEmpty } from '../../common/utility/helperFunc';
const TaxesDropDownComponent = ({
  openState,
  onPressAction,
  taxTitle,
  taxPrice,
  arrData,
  expTrack,
  expTextStyle,
  expTextStyleTwo,
  dropIconColor,
}) => {
  let arrayObj = [];
  if (Array.isArray(arrData)) {
    for (const obj of arrData) {
      arrayObj.push({ name: obj.split('-')[0], value: arrData[obj] });
    }
  } else {
    for (const obj in arrData) {
      arrayObj.push({ name: obj.split('-')[0], value: arrData[obj] });
    }
  }

  return (
    <div>
      <div className="paymentSummary d-flex" onClick={onPressAction}>
        <div className="direction_row d-flex align-items-center">
          <Image
            src={require('../../assets/images/IconsandImages/back icon.png')}
            alt="icon"
            width="12px"
            height="12px"
            style={{
              transform: openState ? 'rotate(90deg)' : 'rotate(270deg)',
              marginRight: 4,
            }}
          />
          <div className="f16-400 my-2">{taxTitle}</div>
        </div>
        <div className="f16-400 my-2">{`₹ ${
          !isEmpty(taxPrice) ? taxPrice?.toFixed(2) : '0.00'
        }`}</div>
      </div>
      {openState ? (
        <div style={{ width: '100%', paddingLeft: 25 }}>
          {!isEmpty(arrayObj) &&
            arrayObj.map((item, index) => {
              return (
                <div className="paymentSummary d-flex">
                  <div className="f12-600 ">{item?.name}</div>
                  <div className="f12-600 ">
                    {!isEmpty(item?.value)
                      ? '₹' + item?.value?.toFixed(2)
                      : '0.00'}
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};
export default TaxesDropDownComponent;
