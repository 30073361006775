import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// Primary Button Component
const PrimaryButton = ({
  children,
  onClick,
  color,
  variant,
  fullWidth,
  disabled,
  sx,
  size,
  startIcon,
  endIcon,
  ...rest
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      fullWidth={fullWidth}
      disabled={disabled}
      size={size}
      startIcon={
        startIcon ? (
          <Box sx={{ display: 'inline-flex' }}>{startIcon}</Box>
        ) : null
      }
      endIcon={
        endIcon ? <Box sx={{ display: 'inline-flex' }}>{endIcon}</Box> : null
      }
      sx={{
        backgroundColor: color,
        '&:hover': {
          backgroundColor: color,
          opacity: 0.8,
        },
        borderRadius: '4px',
        textTransform: 'none',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

// Default Props
PrimaryButton.defaultProps = {
  color: '#EE3A23',
  variant: 'contained',
  fullWidth: false,
  disabled: false,
  size: 'medium',
  sx: {},
  startIcon: null,
  endIcon: null,
};

// Prop Types for validation
PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sx: PropTypes.object,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

export default PrimaryButton;
