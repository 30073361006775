import React from 'react';
import { Card, Button, Image } from 'react-bootstrap';

const RestaurantList = () => {
  const restaurants = [
    { name: 'Xenia', rating: 2.2, distance: '1.2 km' },
    { name: 'The Chinese Wok', rating: 3.5, distance: '2.5 km' },
    { name: 'Punjab Grill', rating: 4.0, distance: '3.2 km' },
    { name: 'Biryani House', rating: 4.2, distance: '4.6 km' },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h5>Always Find a Place to Eat</h5>
      <p style={{ color: 'gray', fontSize: '12px' }}>
        Restaurants are unregistered, do not have any features yet. Nominate and Refer the restaurants to earn rewards{' '}
        <a href="/" style={{ color: '#FF5733' }}>Learn more</a>
      </p>

      {/* Restaurant Cards */}
      {restaurants.map((restaurant, index) => (
        <Card key={index} className="mb-3" style={{ borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Card.Body className="d-flex align-items-center justify-content-between">
            {/* Restaurant Icon */}
            <div style={{ position: 'relative', width: '40px', height: '40px', marginRight: '10px' }}>
  {/* Background Restaurant Icon */}
  <img
    src={require('../../assets/images/IconsandImages/Screen-Shot-2017-04-13-at-2.08.12-PM.png')}
    alt="restaurant-icon-background"
    style={{ width: '100%', height: '100%' }}
  />
  
  {/* Fork and Knife Image */}
  <img
    src={require('../../assets/images/IconsandImages/restaurant_black_24dp.png')} 
    alt="fork-knife-icon"
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '24px',
      height: '24px' 
    }}
  />
</div>


            <div style={{ flexGrow: 1 }}>
              <Card.Title style={{ marginBottom: '4px' }}>{restaurant.name}</Card.Title>
              <div style={{ color: 'gray', fontSize: '14px', display: 'flex', alignItems: 'center' }}>
                <Image
                  src={require('../../assets/images/IconsandImages/star.png')} 
                  alt="star"
                  style={{ width: '16px', height: '16px', marginRight: '4px' }}
                />
                <span>{restaurant.rating} • {restaurant.distance}</span>
              </div>
            </div>
          </Card.Body>
        </Card>
      ))}

      
    </div>
  );
};

export default RestaurantList;
