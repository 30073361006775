import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FC5A47",
    },
  },
});

export default function DatePicker({ label, minDate, maxDate, handleAccept }) {
  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <MobileDatePicker
            className="penIconButton"
            inputFormat="DD / MM / YYYY"
            value={value}
            label={label}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChange}
            onAccept={(e) => handleAccept(value)}
            DialogProps={{
              className: "penIconButton",
            }}
            renderInput={(params) => (
              <TextField
                style={{
                  backgroundColor: "white",
                  boxShadow: "1px 3px 5px #00000029",
                  borderRadius: "5px",
                }}
                {...params}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
