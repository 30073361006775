import React from "react";
import UserRegistration from "../ReusableComponent/UserRegistration";

function Login(props) {
  return (
    <div>
      <UserRegistration open={true} setshowOtpModal={(val) => {}} />
    </div>
  );
}

export default Login;
