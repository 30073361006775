import React from 'react'
import { Drawer,Button } from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';

const CallModal = ({data,open,setCallOpen}) => {
  return (
    <Drawer
    anchor={'bottom'}
    open={open}
    onClose={setCallOpen}
    className="border-radius-call"
    style={{borderRadius:"36px 36px 0 0"}}
  >
    <div className='mx-3 mt-4'>
      <div className="f20-700 my-1">Call The Restaurant</div>
      <div className="f16-500 my-1">{data?.restaurant_name || ""}</div>
      <div className="f24-500">{data?.vendor_id?.country_code + data?.vendor_id?.mobile_number || " "}</div>
      <hr />
      <div className="d-flex align-items-center justify-content-around mb-3">
      <Button variant="outlined" color="inherit" className='px-6 btn-class' size='large' style={{borderRadius:"12px"}} onClick={setCallOpen}>cancel</Button>

      <Button variant="contained" startIcon={<PhoneIcon />} 
      href={`tel:${data?.vendor_id?.mobile_number || ""}`}
       color="error" className='px-4 btn-class whitecolor' size='large'  style={{borderRadius:"12px"}}>call Now</Button>
      </div>

    </div>
  </Drawer>
  )
}

export default CallModal;