import DownloadBar from "../ReusableComponent/downloadar";
import { isEmpty } from "../../common/utility/helperFunc";
import moment from "moment";
import { Button } from "@mui/material";
import {
  activeButtonCheck,
  discountedGroupBook,
  getMinimumAmount,
} from "../../common/utility/expFunctions";
import { useNavigate } from "react-router-dom";

export const BookNowBottomStatic = ({ expData }) => {
  const navigate = useNavigate();
  const HandleBook = () => {
    if (expData?.allowSingleBooking || discountedGroupBook(expData)) {
      navigate("./bookexp", { state: { actionBy: "BOOKING_PAGE" } });
    }
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: "100",
      }}
    >
      <div className="row w-100 m-0">
        <div className="col-12 col-md-6 col-lg-4 p-0">
          <DownloadBar required />
          <div
            className="d-flex align-items-center justify-content-between px-4 py-2"
            style={{
              boxShadow: "0 0px 15px 0px #00000054",
            }}
          >
            <div className="d-flex align-items-center">
              <div className="f20-500">₹ {getMinimumAmount(expData)}</div>
              <div className="f10-400 ml-2">onwards</div>
            </div>
            <Button
              className=" f14-500 rounded"
              size="lg"
              variant="contained"
              color="error"
              sx={{ textTransform: "none" }}
              disabled={activeButtonCheck(expData) || expData?.softOnboarding}
              onClick={HandleBook}
            >
              Book Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
