import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { assets } from '../../assets/assets';

// Styled components for the modal overlay and content
const OverlayBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
});

const ContentBox = styled(Box)({
  position: 'relative',
  padding: '2rem',
  borderRadius: '12px',
  backgroundColor: '#fff',
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '400px',
  width: '100%',
  zIndex: 2,
});

const MainImageContainer = styled(Box)({
  position: 'absolute',
  top: '-52px', // Adjust this value to control how much the image is outside
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 3, // Make sure it's above the modal content
});

const MainImage = styled('img')({
  width: '120px',
  //height: '80px',
});

const SuccessModal = ({
  open,
  handleClose,
  mainImage,
  icon,
  title,
  message,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="success-modal"
      aria-describedby="success-message"
    >
      <OverlayBox onClick={handleClose}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* Main Image that is partially outside the modal */}
          {mainImage && (
            <MainImageContainer>
              <MainImage src={mainImage} alt="Success Image" />
            </MainImageContainer>
          )}

          <ContentBox
            onClick={(e) => e.stopPropagation()}
            sx={{ padding: '2rem 5rem' }}
          >
            {/* Icon in place of the close button */}
            <IconButton
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                zIndex: 4,
                marginTop: '-42px',
              }}
              onClick={handleClose}
            >
              {icon}
            </IconButton>

            {/* Title */}
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                marginTop: '40px',
                marginBottom: '8px',
                color: assets.colors.primary,
              }}
            >
              {title || 'Success!'}
            </Typography>

            {/* Message */}
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ color: assets.colors.primary }}
            >
              {message || 'Your action was successful.'}
            </Typography>
          </ContentBox>
        </Box>
      </OverlayBox>
    </Modal>
  );
};

export default SuccessModal;
