import {
  Box,
  Divider,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { assets } from '../../assets/assets';
import ReusableTextField from '../../components/inputFields/ResuableTextField';
import CountryCodeSelect from '../../components/inputFields/PhoneNumberCountryCodesSelect';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useApi from '../../common/api/hooks/useApi';
import UAParser from 'ua-parser-js';

function SignUp() {
  const [countryCode, setCountryCode] = useState('+91');
  const [deviceDetails, setDeviceDetails] = useState({});
  const { handleRequest, loading } = useApi();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  // Fetch device details using UAParser.js
  useEffect(() => {
    const parser = new UAParser();
    const device = parser.getDevice();
    const os = parser.getOS();
    const browser = parser.getBrowser();

    const details = {
      deviceId: navigator.userAgent,
      deviceType: device.type || 'desktop',
      deviceName: device.vendor || 'unknown',
      deviceVersion: browser.version,
      deviceUniqId: navigator.userAgent,
      deviceSystemVersion: os.version,
    };

    setDeviceDetails(details);
  }, []);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      email: '',
      firstName: '',
      lastName: '',
      referralCode: '',
      country_code: '91',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
      email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      referralCode: Yup.string(),
    }),
    onSubmit: async (values) => {
      setErrorMessage('');
      const formData = {
        mobile_number: values.phoneNumber,
        email: values.email.toLowerCase(),
        first_name: values.firstName,
        last_name: values.lastName,
        country_code: countryCode,
        role: 'user',
        referral: values.referralCode,
        ...deviceDetails,
      };

      try {
        await handleRequest('post', '/user-service', formData, {
          successMessage: 'Sign up successful!',
          errorMessage: 'Sign up failed. Please try again.',
          onSuccess: async (response) => {
            console.log('User service response:', response);
            sessionStorage.setItem('signupDetails', JSON.stringify(response));

            const otpPayload = {
              strategy: 'phone',
              mobile_number: values.phoneNumber,
              country_code: countryCode,
              deviceId: deviceDetails.deviceId || '',
              deviceType: deviceDetails.deviceType || '',
              deviceVersion: deviceDetails.deviceVersion || '',
              deviceUniqId: deviceDetails.deviceUniqId || '',
              deviceSystemVersion: deviceDetails.deviceSystemVersion || '',
            };

            try {
              await handleRequest('post', '/otp-authentication', otpPayload, {
                successMessage: 'OTP sent successfully!',
                errorMessage: 'Failed to send OTP. Please try again.',
                onSuccess: (otpResponse) => {
                  console.log('OTP response:', otpResponse);
                  sessionStorage.setItem(
                    'signupOtpPayload',
                    JSON.stringify(otpPayload)
                  );

                  navigate('/enterotp');
                },
              });
            } catch (otpError) {
              console.error('Error during OTP request:', otpError);
              setErrorMessage('Failed to send OTP. Please try again.');
            }
          },
          onError: (error) => {
            if (error.response && error.response.status === 409) {
              setErrorMessage('Email or phone number already exists.');
            } else {
              setErrorMessage(
                'An error occurred during sign up. Please try again.'
              );
            }
          },
        });
      } catch (error) {
        console.error('Error during sign up:', error);
      }
    },
  });

  return (
    <Box>
      <Grid
        container
        spacing={2}
        px={4}
        sx={{
          padding: {
            xs: '3rem 2rem',
            sm: '3rem 2rem',
            md: '3rem 5rem',
            lg: '3rem 5rem',
            xl: '4rem 6rem',
          },
        }}
      >
        <Grid item xs={12} md={6} py={4}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Image
              src={require('../../assets/images/IconsandImages/amealiologowithtext.png')}
              width={'120px'}
              style={{ maxWidth: '100%' }}
            />
            <Typography variant="body1" color="grey">
              Skip
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            pt={2}
          >
            <Typography className="f30-800">Welcome</Typography>
            <Typography
              variant="body1"
              color="initial"
              sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}
            >
              Language{' '}
              <span
                className="f18-700"
                style={{ color: assets.colors.primary }}
              >
                {' '}
                EN
              </span>
            </Typography>
          </Box>
          <Typography className="f14-500" color={assets.colors.greySubText2}>
            Please Login or Sign up to continue
          </Typography>
          <Box my={2}>
            <Image
              src={require('../../assets/images/socialmedia/google.png')}
              width={'40px'}
              className="grey-round-border mr-2"
              style={{ maxWidth: '100%' }}
            />
            <Image
              src={require('../../assets/images/socialmedia/Facebook.png')}
              width={'40px'}
              className="grey-round-border mx-2"
              style={{ maxWidth: '100%' }}
            />
            <Image
              src={require('../../assets/images/socialmedia/apple.png')}
              width={'40px'}
              className="grey-round-border mx-2"
              style={{ maxWidth: '100%' }}
            />
          </Box>
          <Divider>OR</Divider>

          <Box>
            <Typography className="f24-800 mt-3 mb-2">Sign Up</Typography>

            {errorMessage && (
              <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                {errorMessage}
              </Typography>
            )}

            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={2} sx={{ paddingTop: '0px' }}>
                    <CountryCodeSelect
                      countryCode={countryCode}
                      onCountryChange={(value) => setCountryCode(value)}
                      required
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={8} md={10} sx={{ paddingTop: '0px' }}>
                    <ReusableTextField
                      placeholder={'Phone Number*'}
                      size="small"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '0px' }}>
                    <ReusableTextField
                      placeholder={'Email*'}
                      size="small"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ paddingTop: '0px' }}>
                    <ReusableTextField
                      placeholder={'First Name'}
                      size="small"
                      name="firstName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ paddingTop: '0px' }}>
                    <ReusableTextField
                      placeholder={'Last Name'}
                      size="small"
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingTop: '0px' }}>
                    <ReusableTextField
                      placeholder={'Referral Code'}
                      size="small"
                      name="referralCode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.referralCode}
                    />
                  </Grid>
                </Grid>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTermsAccepted}
                      onChange={(e) => setIsTermsAccepted(e.target.checked)}
                      sx={{
                        color: '#808080',
                        '&.Mui-checked': {
                          color: assets.colors.primary,
                        },
                      }}
                    />
                  }
                  label={
                    <Typography className="f16-500 pt-2">
                      By signing up, I accept the{' '}
                      <a
                        href="https://amealio.com/IN/terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span style={{ color: assets.colors.primary }}>
                          Terms & Conditions
                        </span>
                      </a>
                    </Typography>
                  }
                />

                <Grid item xs={12} mt={4} mb={2}>
                  <PrimaryButton
                    type="submit"
                    size="large"
                    fullWidth
                    disabled={
                      loading || formik.isSubmitting || !isTermsAccepted
                    }
                  >
                    {loading || formik.isSubmitting
                      ? 'Submitting...'
                      : 'Sign Up'}
                  </PrimaryButton>
                </Grid>

                <Typography className="f16-500">
                  Already have an account?{' '}
                  <span
                    style={{ color: assets.colors.primary, cursor: 'pointer' }}
                    className="f16-700"
                    onClick={() => navigate('/userlogin')}
                  >
                    Login
                  </span>
                </Typography>
              </Box>
            </form>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex' },
            alignItems: 'center',
          }}
          p={4}
        >
          <Image
            src={require('../../assets/images/IconsandImages/splash1.png')}
            style={{
              width: '560px',
              height: '600px',
              padding: '20px 20px 4px 20px',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SignUp;
