import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import MenuCard from '../ReusableComponent/menuCard';

const RecommendedFoodItems = ({ hide, item }) => {
  return (
    <>
      <Carousel
        variant="dark"
        controls={false}
        indicators={true}
        slide
        style={{ marginBottom: '30px' }}
      >
        {item?.map((data) => {
          return (
            <Carousel.Item key={data?._id}>
              <MenuCard hide={hide} item={data} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
};

export default RecommendedFoodItems;
