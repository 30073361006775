import React from 'react';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'; // Importing ArrowBackIosIcon
import { assets } from '../../assets/assets';

// Reusable GoBackButton component
const GoBackButton = ({ route, label = 'Go Back', sx }) => {
  const navigate = useNavigate();

  // Function to handle navigation
  const handleGoBack = () => {
    if (route) {
      navigate(route); // Navigate to the provided route
    } else {
      navigate(-1); // Go back to the previous page
    }
  };

  return (
    <Button
      onClick={handleGoBack}
      variant="text"
      fullWidth // Makes the button take full width
      startIcon={
        <ArrowBackIosIcon
          sx={{ fontSize: '16px', marginRight: '4px' }} // Smaller icon size and spacing
        />
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start', // Align text and icon to the left
        color: assets.colors.primary,
        textTransform: 'none', // Disable uppercase transformation
        padding: '10px 16px', // Add padding for better UX
        ...sx,
      }}
    >
      <Typography variant="body1">{label}</Typography>
    </Button>
  );
};

export default GoBackButton;
