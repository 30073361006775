import React, { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";
import { useNavigate } from "react-router-dom";
import { NOTAVAILABLEIMAGE } from "../../common/enums/GlobalEnums";
import { Image } from "react-bootstrap";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";

const WriteReview = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(2);
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="w-100">
        <div
          className="restaurant_landingPage"
          // style={{ opacity: "0.7" }}
        ></div>
        <div className="restaurant_card_main">
          <div className="row w-100 m-0">
            <div
              className="col-12 col-md-6 col-lg-4 p-0 px-2"
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "20px 20px 0 0",
              }}
            >
              <div className="px-2">
                <div className="f18-700 mt-4">Rate your experience with us</div>
                <div>
                  <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                  <div className="restaurant_title_card my-4 mx-0">
                    <div className="row mx-0 my-2 py-3">
                      <div className="col-4">
                        <Image
                          src={NOTAVAILABLEIMAGE}
                          alt="icon"
                          width="100px"
                          height="50x"
                          style={{ maxWidth: "100%" }}
                        />
                      </div>
                      <div className="col-8">
                        <div className="f16-700">Street Meat</div>
                        <div className="f14-400">
                          Data:<span className="f16-700">Aug 31,2022</span>
                        </div>
                        <div className="f14-400">
                          Request Number:
                          <span className="f16-700">12345678</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="f16-700">Write A Review</div>
                    <div className="my-4">
                      <TextField
                        id="time"
                        type="text"
                        minRows={4}
                        multiline={true}
                        placeholder="write a review"
                        fullWidth
                        className="shadow border-none"
                        style={{ border: "none" }}
                        //  variant="standard"
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between align-items-center my-3 ">
                    <Button
                       onClick={goBack}
                      variant="outlined"
                      style={{
                        borderRadius: "15px",
                        color: "black",
                        border: "1px solid #C4C4C4",
                        textTransform: "capitalize",
                      }}
                      className="bg-white mr-2"
                      fullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      // onClick={nextStage}
                      variant="contained"
                      style={{
                        borderRadius: "15px",
                        backgroundColor: "rgb(252, 90, 71) !important",
                        textTransform: "capitalize",
                      }}
                      className="bg-danger ml-2"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WriteReview;
