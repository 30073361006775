import React from 'react';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videosData }) => {
  return (
    <>
      <video
        autoPlay={true}
        controls
        src={videosData?.[0]?.video}
        loop
        muted={false}
        playsInline
        webkit-playsinline="true" // iOS-specific attribute
        x-webkit-airplay="deny"
      />
      {/* <Player autoPlay style={{ margin: '20px' }}>
        <source src={videosData?.[0]?.video} />
        <LoadingSpinner />
        <BigPlayButton position="center" />
      </Player> */}
      {/* <ReactPlayer url={videosData[0]?.video}  playing={true} loop={true} light={true} width={"100%"} height={''} volume={0} muted={true} playIcon={true}/> */}
    </>
  );
};

export default VideoPlayer;
