import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';

const CountDown = (props) => {
  const { waitNew } = props;
  const [time, setTime] = useState(waitNew / 1000);

  useEffect(() => {
    let interval;

    const handleInterval = () => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    };

    if (time > 0) {
      interval = setInterval(handleInterval, 1000);
    }

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    // Update time when waitNew changes
    setTime(waitNew / 1000);
  }, [waitNew]);

  const formatTime = (value) =>
    value < 10 && value >= 0
      ? `0${Math.round(value)}`
      : value < 0
      ? '00'
      : `${Math.round(value)}`;

  const seconds = time % 60;
  const minutes = Math.floor((time / 60) % 60);
  const hours = Math.floor((time / 3600) % 24);
  const days = Math.floor(time / (3600 * 24));

  return (
    <>
      <div className="timer">
        <span className="timer-value">
          {`${formatTime(days)}`}
          <span className="f14-500">D :</span>
        </span>
        <span className="timer-value">
          {`${formatTime(hours)}`}
          <span className="f14-500">H :</span>
        </span>
        <span className="timer-value">
          {`${formatTime(minutes)}`}
          <span className="f14-500">M :</span>
        </span>
        <span className="timer-value">
          {`${formatTime(seconds)}`}
          <span className="f14-500">S</span>
        </span>
      </div>
    </>
  );
};

export const TrackCountDown = (props) => {
  return (
    <>
      <Countdown date={props?.untill} />,
    </>
  );
};

export default CountDown;
