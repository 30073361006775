import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AXIOS_TIMEOUT=15000;   //15 seconds

export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    timeout : AXIOS_TIMEOUT,
    // withCredentials: true
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    timeout : AXIOS_TIMEOUT,
    withCredentials: true
});
